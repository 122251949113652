// tslint:disable
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Money: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptRequestResult = OrderStateTransitionError | Request;

export type ActiveOrderError = ErrorResult & {
  __typename?: 'ActiveOrderError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
};

export type ActiveOrderInput = {
  orderId?: InputMaybe<OrderTokenActiveOrderInput>;
};

export type ActiveOrderResult = NoActiveOrderError | Order;

export type AddLateImagesToOrderInput = {
  assetComment?: InputMaybe<Scalars['String']['input']>;
  assetIds: Array<Scalars['ID']['input']>;
  imagesType: OrderImagesType;
  orderId: Scalars['ID']['input'];
};

export type AddLateImagesToOrderResult = ImagesCountError | Order;

export type AddPaymentInput = {
  metadata: Scalars['JSON']['input'];
  method: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type AddPaymentToOrderResult = IneligiblePaymentMethodError | NoActiveOrderError | Order | OrderPaymentStateError | OrderStateTransitionError | PaymentDeclinedError | PaymentFailedError;

export type AddSocialLinkInput = {
  link: Scalars['String']['input'];
  platform: SocialLinkType;
};

export type Address = Node & {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country: Country;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  defaultBillingAddress?: Maybe<Scalars['Boolean']['output']>;
  defaultShippingAddress?: Maybe<Scalars['Boolean']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  streetLine1: Scalars['String']['output'];
  streetLine2?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Adjustment = {
  __typename?: 'Adjustment';
  adjustmentSource: Scalars['String']['output'];
  amount: Scalars['Money']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  type: AdjustmentType;
};

export enum AdjustmentType {
  DistributedOrderPromotion = 'DISTRIBUTED_ORDER_PROMOTION',
  Other = 'OTHER',
  Promotion = 'PROMOTION'
}

export type AlreadyCommunityMemberError = ErrorResult & {
  __typename?: 'AlreadyCommunityMemberError';
  customerId: Scalars['ID']['output'];
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type AlreadyLoggedInError = ErrorResult & {
  __typename?: 'AlreadyLoggedInError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type AlreadyRefundedError = ErrorResult & {
  __typename?: 'AlreadyRefundedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  refundId: Scalars['ID']['output'];
};

export type AppleAuthInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type ApplyCouponCodeResult = CouponCodeExpiredError | CouponCodeInvalidError | CouponCodeLimitError | Order;

export type ApplyVoucherResult = OrderAlreadyHasVoucherError | Voucher | VoucherAlreadyClaimedError | VoucherEmptyError | VoucherExpiredError | VoucherWrongOrderStateError;

export type Asset = Node & {
  __typename?: 'Asset';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<AssetCustomFields>;
  fileSize: Scalars['Int']['output'];
  focalPoint?: Maybe<Coordinate>;
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  preview: Scalars['String']['output'];
  source: Scalars['String']['output'];
  tags: Array<Tag>;
  type: AssetType;
  updatedAt: Scalars['DateTime']['output'];
  width: Scalars['Int']['output'];
};

export type AssetCustomFields = {
  __typename?: 'AssetCustomFields';
  uploadContext?: Maybe<Scalars['String']['output']>;
};

export type AssetFilterParameter = {
  _and?: InputMaybe<Array<AssetFilterParameter>>;
  _or?: InputMaybe<Array<AssetFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  fileSize?: InputMaybe<NumberOperators>;
  height?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  mimeType?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  preview?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  uploadContext?: InputMaybe<StringOperators>;
  width?: InputMaybe<NumberOperators>;
};

export type AssetList = PaginatedList & {
  __typename?: 'AssetList';
  items: Array<Asset>;
  totalItems: Scalars['Int']['output'];
};

export type AssetListOptions = {
  filter?: InputMaybe<AssetFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AssetSortParameter>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsOperator?: InputMaybe<LogicalOperator>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  fileSize?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  preview?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploadContext?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export enum AssetType {
  Binary = 'BINARY',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export enum AssetUploadContext {
  Chat = 'CHAT',
  Documentation = 'DOCUMENTATION',
  Product = 'PRODUCT',
  Profile = 'PROFILE',
  PublicContent = 'PUBLIC_CONTENT',
  Report = 'REPORT',
  Unknown = 'UNKNOWN'
}

export type AssetsOutOfBoundsError = ErrorResult & {
  __typename?: 'AssetsOutOfBoundsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type AssignAssetsToChannelInput = {
  assetIds: Array<Scalars['ID']['input']>;
  channelId: Scalars['ID']['input'];
};

export type AssignProductToChannelInput = {
  channels: Array<ChannelPriceInput>;
  productId: Scalars['ID']['input'];
};

export type AuthenticationInput = {
  apple?: InputMaybe<AppleAuthInput>;
  google?: InputMaybe<GoogleAuthInput>;
  native?: InputMaybe<NativeAuthInput>;
};

export type AuthenticationMethod = Node & {
  __typename?: 'AuthenticationMethod';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  strategy: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AuthenticationResult = CurrentUser | InvalidCredentialsError | NotVerifiedError;

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolderName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  last4: Scalars['String']['output'];
};

export type BlockProductInput = {
  endDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type BlockProductResult = DateOutOfBoundsError | Success;

export type BlockUserResult = ActiveOrderError | Success;

export type BooleanCustomFieldConfig = CustomField & {
  __typename?: 'BooleanCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type BooleanListOperators = {
  inList: Scalars['Boolean']['input'];
};

export type BooleanOperators = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BooleanStructFieldConfig = StructField & {
  __typename?: 'BooleanStructFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type BusinessProfile = Node & {
  __typename?: 'BusinessProfile';
  businessName?: Maybe<Scalars['String']['output']>;
  businessType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  registrationNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vat?: Maybe<Scalars['String']['output']>;
};

export type CancelActiveOrderError = ErrorResult & {
  __typename?: 'CancelActiveOrderError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  orderState: Scalars['String']['output'];
};

export type CancelOrderInput = {
  lines?: InputMaybe<Array<OrderLineInput>>;
  orderId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CancelOrderResult = CancelActiveOrderError | EmptyOrderLineSelectionError | MultipleOrderError | Order | OrderStateTransitionError | QuantityTooGreatError;

export type Channel = Node & {
  __typename?: 'Channel';
  availableCurrencyCodes: Array<CurrencyCode>;
  availableLanguageCodes?: Maybe<Array<LanguageCode>>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use defaultCurrencyCode instead */
  currencyCode: CurrencyCode;
  customFields?: Maybe<ChannelCustomFields>;
  defaultCurrencyCode: CurrencyCode;
  defaultLanguageCode: LanguageCode;
  defaultShippingZone?: Maybe<Zone>;
  defaultTaxZone?: Maybe<Zone>;
  id: Scalars['ID']['output'];
  outOfStockThreshold?: Maybe<Scalars['Int']['output']>;
  pricesIncludeTax: Scalars['Boolean']['output'];
  seller?: Maybe<Seller>;
  token: Scalars['String']['output'];
  trackInventory?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChannelCustomFields = {
  __typename?: 'ChannelCustomFields';
  name?: Maybe<Scalars['String']['output']>;
};

export type ChannelPriceInput = {
  channelId: Scalars['ID']['input'];
  priceFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  _id: Scalars['ID']['output'];
  audio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  image?: Maybe<Scalars['String']['output']>;
  received: Scalars['Boolean']['output'];
  system?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  user: MessageUser;
  video?: Maybe<Scalars['String']['output']>;
};

export type ClaimVoucherResult = Voucher | VoucherAlreadyClaimedError;

export type ClientSecret = {
  __typename?: 'ClientSecret';
  method: PaymentMethodProvider;
  token: Scalars['String']['output'];
};

export type Collection = Node & {
  __typename?: 'Collection';
  assets: Array<Asset>;
  breadcrumbs: Array<CollectionBreadcrumb>;
  children?: Maybe<Array<Collection>>;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  featuredAsset?: Maybe<Asset>;
  filters: Array<ConfigurableOperation>;
  id: Scalars['ID']['output'];
  languageCode?: Maybe<LanguageCode>;
  name: Scalars['String']['output'];
  parent?: Maybe<Collection>;
  parentId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  productVariants: ProductVariantList;
  slug: Scalars['String']['output'];
  translations: Array<CollectionTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CollectionProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type CollectionBreadcrumb = {
  __typename?: 'CollectionBreadcrumb';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type CollectionFilterParameter = {
  _and?: InputMaybe<Array<CollectionFilterParameter>>;
  _or?: InputMaybe<Array<CollectionFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  parentId?: InputMaybe<IdOperators>;
  position?: InputMaybe<NumberOperators>;
  slug?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CollectionList = PaginatedList & {
  __typename?: 'CollectionList';
  items: Array<Collection>;
  totalItems: Scalars['Int']['output'];
};

export type CollectionListOptions = {
  filter?: InputMaybe<CollectionFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CollectionSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
  topLevelOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollectionResult = {
  __typename?: 'CollectionResult';
  collection: Collection;
  count: Scalars['Int']['output'];
};

export type CollectionSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionTranslation = {
  __typename?: 'CollectionTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Community = Node & {
  __typename?: 'Community';
  channel: Channel;
  commission: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  languageCode: LanguageCode;
  location?: Maybe<CommunityLocation>;
  name: Scalars['String']['output'];
  requirementFilters: Array<ConfigurableOperation>;
  slug: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  totalMembers: Scalars['Int']['output'];
  translations: Array<CommunityTranslation>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visibility: CommunityVisibility;
};

export type CommunityFilterParameter = {
  _and?: InputMaybe<Array<CommunityFilterParameter>>;
  _or?: InputMaybe<Array<CommunityFilterParameter>>;
  commission?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  introduction?: InputMaybe<StringOperators>;
  isMember?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  slug?: InputMaybe<StringOperators>;
  totalMembers?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  visibility?: InputMaybe<StringOperators>;
};

export type CommunityInvitation = Node & {
  __typename?: 'CommunityInvitation';
  attachments?: Maybe<Scalars['JSON']['output']>;
  community: Community;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommunityInvitationExistsError = ErrorResult & {
  __typename?: 'CommunityInvitationExistsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type CommunityInvitationFilterParameter = {
  _and?: InputMaybe<Array<CommunityInvitationFilterParameter>>;
  _or?: InputMaybe<Array<CommunityInvitationFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  expiresAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  text?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CommunityInvitationList = PaginatedList & {
  __typename?: 'CommunityInvitationList';
  items: Array<CommunityInvitation>;
  totalItems: Scalars['Int']['output'];
};

export type CommunityInvitationListOptions = {
  filter?: InputMaybe<CommunityInvitationFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip: Scalars['Int']['input'];
  sort?: InputMaybe<CommunityInvitationSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunityInvitationSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommunityInviteResult = CommunityInvitation | CommunityInvitationExistsError;

export type CommunityJoinResult = AlreadyCommunityMemberError | Community | JoinRequirementError | NoCommunityAccessError;

export type CommunityList = PaginatedList & {
  __typename?: 'CommunityList';
  items: Array<Community>;
  totalItems: Scalars['Int']['output'];
};

export type CommunityListOptions = {
  filter?: InputMaybe<CommunityFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CommunitySortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunityLocation = Node & {
  __typename?: 'CommunityLocation';
  city?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type CommunityMember = Node & {
  __typename?: 'CommunityMember';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  profile: Profile;
  role: CommunityRole;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommunityMemberFilterParameter = {
  _and?: InputMaybe<Array<CommunityMemberFilterParameter>>;
  _or?: InputMaybe<Array<CommunityMemberFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  role?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CommunityMemberList = PaginatedList & {
  __typename?: 'CommunityMemberList';
  items: Array<CommunityMember>;
  totalItems: Scalars['Int']['output'];
};

export type CommunityMemberListOptions = {
  filter?: InputMaybe<CommunityMemberFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CommunityMemberSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunityMemberSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommunityResponse = {
  __typename?: 'CommunityResponse';
  items: Array<CommunityResult>;
  searchTimeMs: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type CommunityResult = {
  __typename?: 'CommunityResult';
  createdAt: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  featuredAssetPreview?: Maybe<Scalars['String']['output']>;
  highlights: Array<ResultHighlight>;
  id: Scalars['String']['output'];
  members: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  requirements: Scalars['Boolean']['output'];
  score: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  visibility: Scalars['String']['output'];
};

export enum CommunityRole {
  Admin = 'ADMIN',
  Ambassador = 'AMBASSADOR',
  Member = 'MEMBER',
  Moderator = 'MODERATOR'
}

export type CommunitySortParameter = {
  commission?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  introduction?: InputMaybe<SortOrder>;
  isMember?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  totalMembers?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommunityTranslation = {
  __typename?: 'CommunityTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum CommunityVisibility {
  Closed = 'CLOSED',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type ConfigArg = {
  __typename?: 'ConfigArg';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ConfigArgDefinition = {
  __typename?: 'ConfigArgDefinition';
  defaultValue?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type ConfigArgInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation';
  args: Array<ConfigArg>;
  code: Scalars['String']['output'];
};

export type ConfigurableOperationDefinition = {
  __typename?: 'ConfigurableOperationDefinition';
  args: Array<ConfigArgDefinition>;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type ConfigurableOperationInput = {
  arguments: Array<ConfigArgInput>;
  code: Scalars['String']['input'];
};

export enum ContextType {
  Order = 'ORDER',
  Product = 'PRODUCT',
  Request = 'REQUEST'
}

export type Conversation = Node & {
  __typename?: 'Conversation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  members?: Maybe<Array<Profile>>;
  messages?: Maybe<Array<ChatMessage>>;
  orders: OrderList;
  products?: Maybe<Array<Product>>;
  requests: RequestList;
  type: ConversationType;
  unreadMessages: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type ConversationOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
};


export type ConversationRequestsArgs = {
  options?: InputMaybe<RequestListOptions>;
};

export type ConversationFilterParameter = {
  _and?: InputMaybe<Array<ConversationFilterParameter>>;
  _or?: InputMaybe<Array<ConversationFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  lastActivityAt?: InputMaybe<DateOperators>;
  type?: InputMaybe<StringOperators>;
  unreadMessages?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ConversationList = PaginatedList & {
  __typename?: 'ConversationList';
  items: Array<Conversation>;
  totalItems: Scalars['Int']['output'];
};

export type ConversationListOptions = {
  filter?: InputMaybe<ConversationFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip: Scalars['Int']['input'];
  sort?: InputMaybe<ConversationSortParameter>;
  take: Scalars['Int']['input'];
};

export type ConversationSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastActivityAt?: InputMaybe<SortOrder>;
  unreadMessages?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ConversationType {
  Private = 'PRIVATE',
  System = 'SYSTEM'
}

export type Coordinate = {
  __typename?: 'Coordinate';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type CoordinateInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type Country = Node & Region & {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']['output']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CountryList = PaginatedList & {
  __typename?: 'CountryList';
  items: Array<Country>;
  totalItems: Scalars['Int']['output'];
};

export type CouponCodeExpiredError = ErrorResult & {
  __typename?: 'CouponCodeExpiredError';
  couponCode: Scalars['String']['output'];
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type CouponCodeInvalidError = ErrorResult & {
  __typename?: 'CouponCodeInvalidError';
  couponCode: Scalars['String']['output'];
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type CouponCodeLimitError = ErrorResult & {
  __typename?: 'CouponCodeLimitError';
  couponCode: Scalars['String']['output'];
  errorCode: ErrorCode;
  limit: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  customFields?: InputMaybe<Scalars['JSON']['input']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  streetLine1: Scalars['String']['input'];
  streetLine2?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetCustomFieldsInput = {
  uploadContext?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetInput = {
  customFields?: InputMaybe<CreateAssetCustomFieldsInput>;
  file: Scalars['Upload']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateAssetResult = Asset | MimeTypeError;

export type CreateBusinessInput = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessType: Scalars['String']['input'];
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerCustomFieldsInput = {
  allowNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerInput = {
  customFields?: InputMaybe<CreateCustomerCustomFieldsInput>;
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInquiryInput = {
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  expectedPrice: Scalars['Float']['input'];
  productName: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CreateOwnerProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryId: Scalars['ID']['input'];
  delivery: ProductDeliveryOptions;
  description: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>;
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  pricing: CreateProductPriceInput;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchasePrice?: InputMaybe<Scalars['Float']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  shippingFee?: InputMaybe<Scalars['Int']['input']>;
  subCategoryId: Scalars['ID']['input'];
};

export type CreateProductPriceInput = {
  basePrice: Scalars['Int']['input'];
  prices?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateRequestInput = {
  endDate: Scalars['String']['input'];
  isHourly?: InputMaybe<Scalars['Boolean']['input']>;
  productVariantId: Scalars['ID']['input'];
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['String']['input'];
};

export type CreateRequestResult = DateOutOfBoundsError | ProductBlockedOrBookedError | Request | RequestCollisionError;

export type CreateStripeAccountInput = {
  IBAN: Scalars['String']['input'];
  ipAddress: Scalars['String']['input'];
};

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  authStrategy: Scalars['String']['output'];
  channels: Array<CurrentUserChannel>;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  profileId?: Maybe<Scalars['ID']['output']>;
};

export type CurrentUserChannel = {
  __typename?: 'CurrentUserChannel';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions: Array<Permission>;
  token: Scalars['String']['output'];
};

export type CustomField = {
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type CustomFieldConfig = BooleanCustomFieldConfig | DateTimeCustomFieldConfig | FloatCustomFieldConfig | IntCustomFieldConfig | LocaleStringCustomFieldConfig | LocaleTextCustomFieldConfig | RelationCustomFieldConfig | StringCustomFieldConfig | StructCustomFieldConfig | TextCustomFieldConfig;

export type Customer = Node & {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Address>>;
  commissionPercentage?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<CustomerCustomFields>;
  emailAddress: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  orders: OrderList;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};


export type CustomerOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
};

export type CustomerCustomFields = {
  __typename?: 'CustomerCustomFields';
  allowNewsletter?: Maybe<Scalars['Boolean']['output']>;
  birthday?: Maybe<Scalars['DateTime']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
};

export type CustomerFeedback = Node & {
  __typename?: 'CustomerFeedback';
  author: Profile;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  feedbackRating: Scalars['Int']['output'];
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orderId: Scalars['ID']['output'];
  recipient: Profile;
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated API caching makes this field impossible to use */
  userRelation: UserEntityRelation;
};

export type CustomerFeedbackFilterParameter = {
  _and?: InputMaybe<Array<CustomerFeedbackFilterParameter>>;
  _or?: InputMaybe<Array<CustomerFeedbackFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  feedbackRating?: InputMaybe<NumberOperators>;
  headline?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  orderId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userRelation?: InputMaybe<StringOperators>;
};

export type CustomerFeedbackList = PaginatedList & {
  __typename?: 'CustomerFeedbackList';
  items: Array<CustomerFeedback>;
  totalItems: Scalars['Int']['output'];
};

export type CustomerFeedbackListOptions = {
  filter?: InputMaybe<CustomerFeedbackFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CustomerFeedbackSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerFeedbackSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  feedbackRating?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerFilterParameter = {
  _and?: InputMaybe<Array<CustomerFilterParameter>>;
  _or?: InputMaybe<Array<CustomerFilterParameter>>;
  allowNewsletter?: InputMaybe<BooleanOperators>;
  birthday?: InputMaybe<DateOperators>;
  commissionPercentage?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  emailAddress?: InputMaybe<StringOperators>;
  firstName?: InputMaybe<StringOperators>;
  gender?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  language?: InputMaybe<StringOperators>;
  lastName?: InputMaybe<StringOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  title?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export enum CustomerGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY',
  NotSpecified = 'NOT_SPECIFIED'
}

export type CustomerGroup = Node & {
  __typename?: 'CustomerGroup';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  customers: CustomerList;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerGroupCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>;
};

export type CustomerList = PaginatedList & {
  __typename?: 'CustomerList';
  items: Array<Customer>;
  totalItems: Scalars['Int']['output'];
};

export type CustomerListOptions = {
  filter?: InputMaybe<CustomerFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CustomerSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerSortParameter = {
  allowNewsletter?: InputMaybe<SortOrder>;
  birthday?: InputMaybe<SortOrder>;
  commissionPercentage?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DateListOperators = {
  inList: Scalars['DateTime']['input'];
};

export type DateOperators = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
  between?: InputMaybe<DateRange>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DateOutOfBoundsError = ErrorResult & {
  __typename?: 'DateOutOfBoundsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type DateRange = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type DateTimeCustomFieldConfig = CustomField & {
  __typename?: 'DateTimeCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type DateTimeStructFieldConfig = StructField & {
  __typename?: 'DateTimeStructFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  step?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type DefaultLocationDeleteError = ErrorResult & {
  __typename?: 'DefaultLocationDeleteError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type DeleteAssetInput = {
  assetId: Scalars['ID']['input'];
  deleteFromAllChannels?: InputMaybe<Scalars['Boolean']['input']>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteAssetsInput = {
  assetIds: Array<Scalars['ID']['input']>;
  deleteFromAllChannels?: InputMaybe<Scalars['Boolean']['input']>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteProductResult = ActiveOrderError | DeletionResponse;

export type DeleteUploadLocationResult = DefaultLocationDeleteError | DeletionResponse;

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  message?: Maybe<Scalars['String']['output']>;
  result: DeletionResult;
};

export enum DeletionResult {
  Deleted = 'DELETED',
  NotDeleted = 'NOT_DELETED'
}

export type DeliverOrderInput = {
  assetComment?: InputMaybe<Scalars['String']['input']>;
  assetIds: Array<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
};

export type Discount = {
  __typename?: 'Discount';
  adjustmentSource: Scalars['String']['output'];
  amount: Scalars['Money']['output'];
  amountWithTax: Scalars['Money']['output'];
  description: Scalars['String']['output'];
  type: AdjustmentType;
};

export enum DurationContextType {
  Blocked = 'BLOCKED',
  Booked = 'BOOKED'
}

export type EmailAddressConflictError = ErrorResult & {
  __typename?: 'EmailAddressConflictError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type EmptyOrderLineSelectionError = ErrorResult & {
  __typename?: 'EmptyOrderLineSelectionError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export enum ErrorCode {
  ActiveOrderError = 'ACTIVE_ORDER_ERROR',
  AlreadyCommunityMemberError = 'ALREADY_COMMUNITY_MEMBER_ERROR',
  AlreadyLoggedInError = 'ALREADY_LOGGED_IN_ERROR',
  AlreadyRefundedError = 'ALREADY_REFUNDED_ERROR',
  AssetsOutOfBoundsError = 'ASSETS_OUT_OF_BOUNDS_ERROR',
  CancelActiveOrderError = 'CANCEL_ACTIVE_ORDER_ERROR',
  CommunityInvitationExistsError = 'COMMUNITY_INVITATION_EXISTS_ERROR',
  CouponCodeExpiredError = 'COUPON_CODE_EXPIRED_ERROR',
  CouponCodeInvalidError = 'COUPON_CODE_INVALID_ERROR',
  CouponCodeLimitError = 'COUPON_CODE_LIMIT_ERROR',
  DateOutOfBoundsError = 'DATE_OUT_OF_BOUNDS_ERROR',
  DefaultLocationDeleteError = 'DEFAULT_LOCATION_DELETE_ERROR',
  EmailAddressConflictError = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  EmptyOrderLineSelectionError = 'EMPTY_ORDER_LINE_SELECTION_ERROR',
  FeedbackExistsError = 'FEEDBACK_EXISTS_ERROR',
  FulfillmentStateTransitionError = 'FULFILLMENT_STATE_TRANSITION_ERROR',
  GuestCheckoutError = 'GUEST_CHECKOUT_ERROR',
  IdentifierChangeTokenExpiredError = 'IDENTIFIER_CHANGE_TOKEN_EXPIRED_ERROR',
  IdentifierChangeTokenInvalidError = 'IDENTIFIER_CHANGE_TOKEN_INVALID_ERROR',
  ImagesCountError = 'IMAGES_COUNT_ERROR',
  IneligiblePaymentMethodError = 'INELIGIBLE_PAYMENT_METHOD_ERROR',
  IneligibleShippingMethodError = 'INELIGIBLE_SHIPPING_METHOD_ERROR',
  InsufficientStockError = 'INSUFFICIENT_STOCK_ERROR',
  InvalidCredentialsError = 'INVALID_CREDENTIALS_ERROR',
  JoinRequirementError = 'JOIN_REQUIREMENT_ERROR',
  MimeTypeError = 'MIME_TYPE_ERROR',
  MissingInputError = 'MISSING_INPUT_ERROR',
  MissingPasswordError = 'MISSING_PASSWORD_ERROR',
  MultipleOrderError = 'MULTIPLE_ORDER_ERROR',
  NativeAuthStrategyError = 'NATIVE_AUTH_STRATEGY_ERROR',
  NegativeQuantityError = 'NEGATIVE_QUANTITY_ERROR',
  NothingToRefundError = 'NOTHING_TO_REFUND_ERROR',
  NotVerifiedError = 'NOT_VERIFIED_ERROR',
  NoActiveOrderError = 'NO_ACTIVE_ORDER_ERROR',
  NoCommunityAccessError = 'NO_COMMUNITY_ACCESS_ERROR',
  OrderAlreadyHasVoucherError = 'ORDER_ALREADY_HAS_VOUCHER_ERROR',
  OrderInterceptorError = 'ORDER_INTERCEPTOR_ERROR',
  OrderLimitError = 'ORDER_LIMIT_ERROR',
  OrderModificationError = 'ORDER_MODIFICATION_ERROR',
  OrderPaymentStateError = 'ORDER_PAYMENT_STATE_ERROR',
  OrderStateTransitionError = 'ORDER_STATE_TRANSITION_ERROR',
  PasswordAlreadySetError = 'PASSWORD_ALREADY_SET_ERROR',
  PasswordResetTokenExpiredError = 'PASSWORD_RESET_TOKEN_EXPIRED_ERROR',
  PasswordResetTokenInvalidError = 'PASSWORD_RESET_TOKEN_INVALID_ERROR',
  PasswordValidationError = 'PASSWORD_VALIDATION_ERROR',
  PaymentDeclinedError = 'PAYMENT_DECLINED_ERROR',
  PaymentFailedError = 'PAYMENT_FAILED_ERROR',
  PaymentOrderMismatchError = 'PAYMENT_ORDER_MISMATCH_ERROR',
  PaymentStateTransitionError = 'PAYMENT_STATE_TRANSITION_ERROR',
  ProductBlockedOrBookedError = 'PRODUCT_BLOCKED_OR_BOOKED_ERROR',
  QuantityTooGreatError = 'QUANTITY_TOO_GREAT_ERROR',
  RefundOrderStateError = 'REFUND_ORDER_STATE_ERROR',
  RefundStateTransitionError = 'REFUND_STATE_TRANSITION_ERROR',
  RequestCollisionError = 'REQUEST_COLLISION_ERROR',
  StripeBalanceNotZeroError = 'STRIPE_BALANCE_NOT_ZERO_ERROR',
  UnknownError = 'UNKNOWN_ERROR',
  VerificationTokenExpiredError = 'VERIFICATION_TOKEN_EXPIRED_ERROR',
  VerificationTokenInvalidError = 'VERIFICATION_TOKEN_INVALID_ERROR',
  VoucherAlreadyClaimedError = 'VOUCHER_ALREADY_CLAIMED_ERROR',
  VoucherEmptyError = 'VOUCHER_EMPTY_ERROR',
  VoucherExpiredError = 'VOUCHER_EXPIRED_ERROR',
  VoucherWrongOrderStateError = 'VOUCHER_WRONG_ORDER_STATE_ERROR'
}

export type ErrorResult = {
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type ExternalIndexResponse = CommunityResponse | ProducttemplateResponse;

export type ExternalIndexResult = CommunityResult | ProducttemplateResult;

export type ExternalSearchFilterByInput = {
  community?: InputMaybe<Array<Scalars['String']['input']>>;
  productTemplate?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExternalSearchInput = {
  filterBy?: InputMaybe<ExternalSearchFilterByInput>;
  indexes: Array<Scalars['String']['input']>;
  prefixMode?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ExternalSearchSortByInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
};

export type ExternalSearchScopedInput = {
  filterBy?: InputMaybe<Array<Scalars['String']['input']>>;
  prefixMode?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Array<Scalars['String']['input']>>;
  take?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
};

export type ExternalSearchSortByInput = {
  community?: InputMaybe<Array<Scalars['String']['input']>>;
  productTemplate?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Facet = Node & {
  __typename?: 'Facet';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  translations: Array<FacetTranslation>;
  updatedAt: Scalars['DateTime']['output'];
  valueList: FacetValueList;
  values: Array<FacetValue>;
};


export type FacetValueListArgs = {
  options?: InputMaybe<FacetValueListOptions>;
};

export type FacetCountData = {
  __typename?: 'FacetCountData';
  counts: Array<FacetCountItem>;
  fieldName: Scalars['String']['output'];
  stats?: Maybe<FacetCountStats>;
};

export type FacetCountItem = {
  __typename?: 'FacetCountItem';
  count: Scalars['Int']['output'];
  highlighted: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FacetCountStats = {
  __typename?: 'FacetCountStats';
  avg: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  sum: Scalars['Float']['output'];
};

export type FacetFilter = {
  code: Scalars['String']['input'];
  facetValueCodes: Array<Scalars['String']['input']>;
};

export type FacetFilterParameter = {
  _and?: InputMaybe<Array<FacetFilterParameter>>;
  _or?: InputMaybe<Array<FacetFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FacetList = PaginatedList & {
  __typename?: 'FacetList';
  items: Array<Facet>;
  totalItems: Scalars['Int']['output'];
};

export type FacetListOptions = {
  filter?: InputMaybe<FacetFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FacetSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type FacetResult = {
  __typename?: 'FacetResult';
  facet: Facet;
  facetValueCounts: Array<FacetValueResult>;
};

export type FacetSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FacetTranslation = {
  __typename?: 'FacetTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FacetValue = Node & {
  __typename?: 'FacetValue';
  childFacetValues?: Maybe<Array<FacetValue>>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<FacetValueCustomFields>;
  facet: Facet;
  facetId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  translations: Array<FacetValueTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FacetValueCustomFields = {
  __typename?: 'FacetValueCustomFields';
  childFacetValue?: Maybe<Array<FacetValue>>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type FacetValueFilterInput = {
  and?: InputMaybe<Scalars['ID']['input']>;
  or?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FacetValueFilterParameter = {
  _and?: InputMaybe<Array<FacetValueFilterParameter>>;
  _or?: InputMaybe<Array<FacetValueFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  facetId?: InputMaybe<IdOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  priority?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FacetValueList = PaginatedList & {
  __typename?: 'FacetValueList';
  items: Array<FacetValue>;
  totalItems: Scalars['Int']['output'];
};

export type FacetValueListOptions = {
  filter?: InputMaybe<FacetValueFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FacetValueSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type FacetValueResult = {
  __typename?: 'FacetValueResult';
  count: Scalars['Int']['output'];
  facetValue: FacetValue;
};

export type FacetValueSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  facetId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FacetValueTranslation = {
  __typename?: 'FacetValueTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FacetsFilterInput = {
  facets: Array<FacetFilter>;
  includeNullValues?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Favorite = Node & {
  __typename?: 'Favorite';
  id: Scalars['ID']['output'];
  product: Product;
};

export type FeedbackExistsError = ErrorResult & {
  __typename?: 'FeedbackExistsError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type FloatCustomFieldConfig = CustomField & {
  __typename?: 'FloatCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type FloatStructFieldConfig = StructField & {
  __typename?: 'FloatStructFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  step?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type Fulfillment = Node & {
  __typename?: 'Fulfillment';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  lines: Array<FulfillmentLine>;
  method: Scalars['String']['output'];
  state: Scalars['String']['output'];
  /** @deprecated Use the `lines` field instead */
  summary: Array<FulfillmentLine>;
  trackingCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FulfillmentLine = {
  __typename?: 'FulfillmentLine';
  fulfillment: Fulfillment;
  fulfillmentId: Scalars['ID']['output'];
  orderLine: OrderLine;
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export type FulfillmentStateTransitionError = ErrorResult & {
  __typename?: 'FulfillmentStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String']['output'];
  message: Scalars['String']['output'];
  toState: Scalars['String']['output'];
  transitionError: Scalars['String']['output'];
};

export type GenerateProductDescriptionInput = {
  category: Scalars['String']['input'];
  productTitle: Scalars['String']['input'];
  subcategory: Scalars['String']['input'];
};

export type GeneratedProductDescription = {
  __typename?: 'GeneratedProductDescription';
  description: Scalars['String']['output'];
  priceRecommendation?: Maybe<Scalars['Int']['output']>;
};

export type GeoDistance = {
  __typename?: 'GeoDistance';
  distanceInMeters: Scalars['Float']['output'];
  field: Scalars['String']['output'];
};

export type Geopoint = {
  __typename?: 'Geopoint';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type GeopointSort = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  sort: SortOrder;
};

export type GetPaymentClientSecretInput = {
  method: PaymentMethodProvider;
  orderId: Scalars['ID']['input'];
};

export type GetPaymentClientSecretResult = ClientSecret | IneligiblePaymentMethodError;

export enum GlobalFlag {
  False = 'FALSE',
  Inherit = 'INHERIT',
  True = 'TRUE'
}

export type GoogleAuthInput = {
  platform: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type GuestCheckoutError = ErrorResult & {
  __typename?: 'GuestCheckoutError';
  errorCode: ErrorCode;
  errorDetail: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type HistoryEntry = Node & {
  __typename?: 'HistoryEntry';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  type: HistoryEntryType;
  updatedAt: Scalars['DateTime']['output'];
};

export type HistoryEntryFilterParameter = {
  _and?: InputMaybe<Array<HistoryEntryFilterParameter>>;
  _or?: InputMaybe<Array<HistoryEntryFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type HistoryEntryList = PaginatedList & {
  __typename?: 'HistoryEntryList';
  items: Array<HistoryEntry>;
  totalItems: Scalars['Int']['output'];
};

export type HistoryEntryListOptions = {
  filter?: InputMaybe<HistoryEntryFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<HistoryEntrySortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type HistoryEntrySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum HistoryEntryType {
  CustomerAddedToGroup = 'CUSTOMER_ADDED_TO_GROUP',
  CustomerAddressCreated = 'CUSTOMER_ADDRESS_CREATED',
  CustomerAddressDeleted = 'CUSTOMER_ADDRESS_DELETED',
  CustomerAddressUpdated = 'CUSTOMER_ADDRESS_UPDATED',
  CustomerDetailUpdated = 'CUSTOMER_DETAIL_UPDATED',
  CustomerEmailUpdateRequested = 'CUSTOMER_EMAIL_UPDATE_REQUESTED',
  CustomerEmailUpdateVerified = 'CUSTOMER_EMAIL_UPDATE_VERIFIED',
  CustomerNote = 'CUSTOMER_NOTE',
  CustomerOwnershipTransfer = 'CUSTOMER_OWNERSHIP_TRANSFER',
  CustomerPasswordResetRequested = 'CUSTOMER_PASSWORD_RESET_REQUESTED',
  CustomerPasswordResetVerified = 'CUSTOMER_PASSWORD_RESET_VERIFIED',
  CustomerPasswordUpdated = 'CUSTOMER_PASSWORD_UPDATED',
  CustomerPayoutUpdate = 'CUSTOMER_PAYOUT_UPDATE',
  CustomerRegistered = 'CUSTOMER_REGISTERED',
  CustomerRemovedFromGroup = 'CUSTOMER_REMOVED_FROM_GROUP',
  CustomerRevokedIdentification = 'CUSTOMER_REVOKED_IDENTIFICATION',
  CustomerVerified = 'CUSTOMER_VERIFIED',
  OrderCancellation = 'ORDER_CANCELLATION',
  OrderCouponApplied = 'ORDER_COUPON_APPLIED',
  OrderCouponRemoved = 'ORDER_COUPON_REMOVED',
  OrderCustomerUpdated = 'ORDER_CUSTOMER_UPDATED',
  OrderFulfillment = 'ORDER_FULFILLMENT',
  OrderFulfillmentTransition = 'ORDER_FULFILLMENT_TRANSITION',
  OrderModified = 'ORDER_MODIFIED',
  OrderNote = 'ORDER_NOTE',
  OrderPaymentTransition = 'ORDER_PAYMENT_TRANSITION',
  OrderPayout = 'ORDER_PAYOUT',
  OrderRefundTransition = 'ORDER_REFUND_TRANSITION',
  OrderStateTransition = 'ORDER_STATE_TRANSITION',
  OrderVoucherApplied = 'ORDER_VOUCHER_APPLIED'
}

export type IdListOperators = {
  inList: Scalars['ID']['input'];
};

export type IdOperators = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IdentifierChangeTokenExpiredError = ErrorResult & {
  __typename?: 'IdentifierChangeTokenExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type IdentifierChangeTokenInvalidError = ErrorResult & {
  __typename?: 'IdentifierChangeTokenInvalidError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type ImagesCountError = ErrorResult & {
  __typename?: 'ImagesCountError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type IneligiblePaymentMethodError = ErrorResult & {
  __typename?: 'IneligiblePaymentMethodError';
  eligibilityCheckerMessage?: Maybe<Scalars['String']['output']>;
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type IneligibleShippingMethodError = ErrorResult & {
  __typename?: 'IneligibleShippingMethodError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Inquiry = Node & {
  __typename?: 'Inquiry';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  expectedPrice: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  productName: Scalars['String']['output'];
  profile: Profile;
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InquiryFilterParameter = {
  _and?: InputMaybe<Array<InquiryFilterParameter>>;
  _or?: InputMaybe<Array<InquiryFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  endDate?: InputMaybe<DateOperators>;
  expectedPrice?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  productName?: InputMaybe<StringOperators>;
  startDate?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type InquiryList = PaginatedList & {
  __typename?: 'InquiryList';
  items: Array<Inquiry>;
  totalItems: Scalars['Int']['output'];
};

export type InquiryListOptions = {
  filter?: InputMaybe<InquiryFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InquirySortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type InquiryResult = DateOutOfBoundsError | Inquiry;

export type InquirySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  expectedPrice?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productName?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InsufficientStockError = ErrorResult & {
  __typename?: 'InsufficientStockError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  order: Order;
  quantityAvailable: Scalars['Int']['output'];
};

export type IntCustomFieldConfig = CustomField & {
  __typename?: 'IntCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type IntStructFieldConfig = StructField & {
  __typename?: 'IntStructFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  step?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type InvalidCredentialsError = ErrorResult & {
  __typename?: 'InvalidCredentialsError';
  authenticationError: Scalars['String']['output'];
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  borrower: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  invoiceDate: Scalars['DateTime']['output'];
  invoiceSnapshot: InvoiceSnapshot;
  lender: Scalars['String']['output'];
  order?: Maybe<Order>;
  owner?: Maybe<User>;
  serviceDate: Scalars['DateTime']['output'];
  type: InvoiceType;
  unique: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

export type InvoiceFilterParameter = {
  _and?: InputMaybe<Array<InvoiceFilterParameter>>;
  _or?: InputMaybe<Array<InvoiceFilterParameter>>;
  borrower?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  identifier?: InputMaybe<StringOperators>;
  invoiceDate?: InputMaybe<DateOperators>;
  lender?: InputMaybe<StringOperators>;
  serviceDate?: InputMaybe<DateOperators>;
  type?: InputMaybe<StringOperators>;
  unique?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  version?: InputMaybe<StringOperators>;
};

export type InvoiceList = PaginatedList & {
  __typename?: 'InvoiceList';
  items: Array<Invoice>;
  totalItems: Scalars['Int']['output'];
};

export type InvoiceListOptions = {
  filter?: InputMaybe<InvoiceFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InvoiceSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceOrderLine = {
  __typename?: 'InvoiceOrderLine';
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  timePeriod: Scalars['String']['output'];
};

export type InvoiceSnapshot = {
  __typename?: 'InvoiceSnapshot';
  ServiceDate: Scalars['DateTime']['output'];
  borrower: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  grossServiceFee: Scalars['Float']['output'];
  grossTotal: Scalars['Float']['output'];
  invoiceDate: Scalars['DateTime']['output'];
  lender: Scalars['String']['output'];
  netServiceFee: Scalars['Float']['output'];
  netTotal: Scalars['Float']['output'];
  orderLines: Array<InvoiceOrderLine>;
  productName: Scalars['String']['output'];
  rentalPeriod: Scalars['Int']['output'];
  shipping?: Maybe<Scalars['Float']['output']>;
  shippingEnabled?: Maybe<Scalars['Boolean']['output']>;
  startDate: Scalars['DateTime']['output'];
  subTotal: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
  taxRate: Scalars['Float']['output'];
  type: InvoiceType;
  unique: Scalars['String']['output'];
};

export type InvoiceSortParameter = {
  borrower?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  invoiceDate?: InputMaybe<SortOrder>;
  lender?: InputMaybe<SortOrder>;
  serviceDate?: InputMaybe<SortOrder>;
  unique?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export enum InvoiceType {
  Fainin = 'FAININ',
  Owner = 'OWNER',
  Renter = 'RENTER'
}

export type IsIdentified = {
  __typename?: 'IsIdentified';
  isIdentified: Scalars['Boolean']['output'];
};

export type JoinRequirementError = ErrorResult & {
  __typename?: 'JoinRequirementError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  requirement: Scalars['String']['output'];
};

export enum LanguageCode {
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  Ar = 'ar',
  As = 'as',
  Az = 'az',
  Be = 'be',
  Bg = 'bg',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ce = 'ce',
  Co = 'co',
  Cs = 'cs',
  Cu = 'cu',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  DeAt = 'de_AT',
  DeCh = 'de_CH',
  Dz = 'dz',
  Ee = 'ee',
  El = 'el',
  En = 'en',
  EnAu = 'en_AU',
  EnCa = 'en_CA',
  EnGb = 'en_GB',
  EnUs = 'en_US',
  Eo = 'eo',
  Es = 'es',
  EsEs = 'es_ES',
  EsMx = 'es_MX',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  FaAf = 'fa_AF',
  Ff = 'ff',
  Fi = 'fi',
  Fo = 'fo',
  Fr = 'fr',
  FrCa = 'fr_CA',
  FrCh = 'fr_CH',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  He = 'he',
  Hi = 'hi',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Ia = 'ia',
  Id = 'id',
  Ig = 'ig',
  Ii = 'ii',
  Is = 'is',
  It = 'it',
  Ja = 'ja',
  Jv = 'jv',
  Ka = 'ka',
  Ki = 'ki',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Ks = 'ks',
  Ku = 'ku',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mg = 'mg',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Nl = 'nl',
  NlBe = 'nl_BE',
  Nn = 'nn',
  Ny = 'ny',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  PtBr = 'pt_BR',
  PtPt = 'pt_PT',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  RoMd = 'ro_MD',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  SwCd = 'sw_CD',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  To = 'to',
  Tr = 'tr',
  Tt = 'tt',
  Ug = 'ug',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Vi = 'vi',
  Vo = 'vo',
  Wo = 'wo',
  Xh = 'xh',
  Yi = 'yi',
  Yo = 'yo',
  Zh = 'zh',
  ZhHans = 'zh_Hans',
  ZhHant = 'zh_Hant',
  Zu = 'zu'
}

export type LocaleStringCustomFieldConfig = CustomField & {
  __typename?: 'LocaleStringCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']['output']>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  pattern?: Maybe<Scalars['String']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type LocaleTextCustomFieldConfig = CustomField & {
  __typename?: 'LocaleTextCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type LocalizedString = {
  __typename?: 'LocalizedString';
  languageCode: LanguageCode;
  value: Scalars['String']['output'];
};

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export type MessageUser = {
  __typename?: 'MessageUser';
  _id: Scalars['ID']['output'];
  avatar: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MigratedUser = Node & {
  __typename?: 'MigratedUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MimeTypeError = ErrorResult & {
  __typename?: 'MimeTypeError';
  errorCode: ErrorCode;
  fileName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
};

export type MissingInputError = ErrorResult & {
  __typename?: 'MissingInputError';
  errorCode: ErrorCode;
  inputField?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type MissingPasswordError = ErrorResult & {
  __typename?: 'MissingPasswordError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type MultipleOrderError = ErrorResult & {
  __typename?: 'MultipleOrderError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCommunityInvite: Community;
  acceptRequest: AcceptRequestResult;
  addItemToOrder: UpdateOrderItemsResult;
  addLateImagesToOrder: AddLateImagesToOrderResult;
  addPaymentToOrder: AddPaymentToOrderResult;
  addSocialLink?: Maybe<SocialLink>;
  addUserInterests: UserInterests;
  adjustOrderLine: UpdateOrderItemsResult;
  allowNotificationPreference: Success;
  applyCouponCode: ApplyCouponCodeResult;
  applyVoucher: ApplyVoucherResult;
  assignAssetsToChannel: Array<Asset>;
  assignProductToChannel: Product;
  authenticate: AuthenticationResult;
  blockProduct: BlockProductResult;
  blockUser: BlockUserResult;
  cancelRequest: Request;
  claimVoucher: ClaimVoucherResult;
  createAssets: Array<CreateAssetResult>;
  createBusinessProfile: BusinessProfile;
  /** @deprecated Should be handled in frontend with TalkJS instead */
  createConversationFromProduct: Conversation;
  createCustomerAddress: Address;
  createInquiry: InquiryResult;
  createProduct: Product;
  createRequest: CreateRequestResult;
  createStripeAccount: Success;
  createUploadLocation: UploadLocation;
  declineRequest: Request;
  deleteAccountRequest: Success;
  deleteAsset: DeletionResponse;
  deleteAssets: DeletionResponse;
  deleteCommunityInvite: Success;
  deleteCustomerAddress: Success;
  deleteFeedback: DeletionResponse;
  deleteInquiry: DeletionResponse;
  deleteProduct: DeleteProductResult;
  deleteRegisteredUserDevice: DeletionResponse;
  deleteStripeAccount: Success;
  deleteUploadLocation: DeleteUploadLocationResult;
  finishTutorial: Success;
  generateExternalWebSDKLink?: Maybe<Scalars['String']['output']>;
  inviteUserToCommunity: CommunityInviteResult;
  joinCommunity: CommunityJoinResult;
  leaveCommunity: Success;
  logSearchListViewed: Scalars['Boolean']['output'];
  logSearchResultClicked: Scalars['Boolean']['output'];
  login: NativeAuthenticationResult;
  logout: Success;
  makeUploadLocationDefault: Success;
  migrationOptIn?: Maybe<OptInResult>;
  migrationOptOut: Success;
  orderCancel: CancelOrderResult;
  orderDeliver: OrderDeliveredResult;
  orderRequestRefund: TransitionOrderToStateResult;
  orderReturn: OrderDeliveredResult;
  orderShippedBack: TransitionOrderToStateResult;
  refreshCustomerVerification: RefreshCustomerVerificationResult;
  refreshIdentificationToken: SumSubToken;
  registerCustomerAccount: RegisterCustomerAccountResult;
  registerUserDevice: UserDevice;
  removeAllOrderLines: RemoveOrderItemsResult;
  removeCouponCode?: Maybe<Order>;
  removeOrderLine: RemoveOrderItemsResult;
  removeProductFromChannel: Product;
  removeSocialLink: Success;
  requestCommunityMembership: Success;
  requestPasswordReset?: Maybe<RequestPasswordResetResult>;
  requestUpdateCustomerEmailAddress: RequestUpdateCustomerEmailAddressResult;
  resetPassword: ResetPasswordResult;
  resetUnreadMarker: Success;
  resetUnreadMarkers: Success;
  savePaypalEmail: Success;
  setCustomerForOrder: SetCustomerForOrderResult;
  setOrderBillingAddress: ActiveOrderResult;
  setOrderCustomFields: ActiveOrderResult;
  setOrderShippingAddress: ActiveOrderResult;
  setOrderShippingMethod: SetOrderShippingMethodResult;
  startIdentificationProcess: SumSubToken;
  submitFeedback: SubmitFeedbackResult;
  submitProductReview: ProductReview;
  submitSearchLocation?: Maybe<SearchLocation>;
  toggleFavorite: Success;
  toggleNewsLetter: Success;
  transitionOrderToState?: Maybe<TransitionOrderToStateResult>;
  unsetOrderBillingAddress: ActiveOrderResult;
  unsetOrderShippingAddress: ActiveOrderResult;
  updateAsset: Asset;
  updateBusinessProfile: BusinessProfile;
  updateCustomer: Customer;
  updateCustomerAddress: Address;
  updateCustomerEmailAddress: UpdateCustomerEmailAddressResult;
  updateCustomerPassword: UpdateCustomerPasswordResult;
  updateFeedback: CustomerFeedback;
  updateInquiry: InquiryResult;
  updateProduct: Product;
  updateProfile: UpdateProfileResult;
  updateStripeAccount: Success;
  verifyCustomerAccount: VerifyCustomerAccountResult;
  voteOnReview: ProductReview;
};


export type MutationAcceptCommunityInviteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAcceptRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddItemToOrderArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  productVariantId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationAddLateImagesToOrderArgs = {
  input: AddLateImagesToOrderInput;
};


export type MutationAddPaymentToOrderArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  input: PaymentInput;
};


export type MutationAddSocialLinkArgs = {
  input: AddSocialLinkInput;
};


export type MutationAddUserInterestsArgs = {
  input: UserInterestsInput;
};


export type MutationAdjustOrderLineArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  orderLineId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationAllowNotificationPreferenceArgs = {
  codes: Array<Scalars['String']['input']>;
};


export type MutationApplyCouponCodeArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  couponCode: Scalars['String']['input'];
};


export type MutationApplyVoucherArgs = {
  code: Scalars['String']['input'];
  orderID: Scalars['ID']['input'];
};


export type MutationAssignAssetsToChannelArgs = {
  input: AssignAssetsToChannelInput;
};


export type MutationAssignProductToChannelArgs = {
  input: AssignProductToChannelInput;
};


export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationBlockProductArgs = {
  input: BlockProductInput;
};


export type MutationBlockUserArgs = {
  profileId: Scalars['ID']['input'];
};


export type MutationCancelRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationClaimVoucherArgs = {
  code: Scalars['String']['input'];
};


export type MutationCreateAssetsArgs = {
  input: Array<CreateAssetInput>;
};


export type MutationCreateBusinessProfileArgs = {
  input: CreateBusinessInput;
};


export type MutationCreateConversationFromProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationCreateCustomerAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateInquiryArgs = {
  input: CreateInquiryInput;
};


export type MutationCreateProductArgs = {
  input: CreateOwnerProductInput;
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationCreateStripeAccountArgs = {
  input: CreateStripeAccountInput;
};


export type MutationCreateUploadLocationArgs = {
  input: SubmitUploadLocationInput;
};


export type MutationDeclineRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};


export type MutationDeleteAssetsArgs = {
  input: DeleteAssetsInput;
};


export type MutationDeleteCommunityInviteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFeedbackArgs = {
  feedbackId: Scalars['ID']['input'];
};


export type MutationDeleteInquiryArgs = {
  inquiryId: Scalars['ID']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRegisteredUserDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUploadLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFinishTutorialArgs = {
  platform: Platform;
};


export type MutationInviteUserToCommunityArgs = {
  communityId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationJoinCommunityArgs = {
  id: Scalars['ID']['input'];
  inviteCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLeaveCommunityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLogSearchListViewedArgs = {
  input: SearchListViewedEventInput;
};


export type MutationLogSearchResultClickedArgs = {
  input: SearchResultClickedEventInput;
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  username: Scalars['String']['input'];
};


export type MutationMakeUploadLocationDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMigrationOptInArgs = {
  userToken: Scalars['String']['input'];
};


export type MutationMigrationOptOutArgs = {
  userToken: Scalars['String']['input'];
};


export type MutationOrderCancelArgs = {
  input: CancelOrderInput;
};


export type MutationOrderDeliverArgs = {
  input: DeliverOrderInput;
};


export type MutationOrderRequestRefundArgs = {
  input: RequestRefundInput;
};


export type MutationOrderReturnArgs = {
  input: ReturnOrderInput;
};


export type MutationOrderShippedBackArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationRefreshCustomerVerificationArgs = {
  emailAddress: Scalars['String']['input'];
};


export type MutationRegisterCustomerAccountArgs = {
  input: RegisterCustomerInput;
};


export type MutationRegisterUserDeviceArgs = {
  input: RegisterUserDeviceInput;
};


export type MutationRemoveAllOrderLinesArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type MutationRemoveCouponCodeArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  couponCode: Scalars['String']['input'];
};


export type MutationRemoveOrderLineArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  orderLineId: Scalars['ID']['input'];
};


export type MutationRemoveProductFromChannelArgs = {
  input: RemoveProductFromChannelInput;
};


export type MutationRemoveSocialLinkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRequestCommunityMembershipArgs = {
  application?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationRequestPasswordResetArgs = {
  emailAddress: Scalars['String']['input'];
};


export type MutationRequestUpdateCustomerEmailAddressArgs = {
  newEmailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationResetUnreadMarkerArgs = {
  entityId: Scalars['String']['input'];
  entityName: Scalars['String']['input'];
};


export type MutationSavePaypalEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSetCustomerForOrderArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  input: CreateCustomerInput;
};


export type MutationSetOrderBillingAddressArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  input: CreateAddressInput;
};


export type MutationSetOrderCustomFieldsArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  input: UpdateOrderInput;
};


export type MutationSetOrderShippingAddressArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  input: CreateAddressInput;
};


export type MutationSetOrderShippingMethodArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  shippingMethodId: Array<Scalars['ID']['input']>;
};


export type MutationSubmitFeedbackArgs = {
  input: SubmitFeedbackInput;
};


export type MutationSubmitProductReviewArgs = {
  input: SubmitProductReviewInput;
};


export type MutationSubmitSearchLocationArgs = {
  input: SubmitSearchLocationInput;
};


export type MutationToggleFavoriteArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationTransitionOrderToStateArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
  state: Scalars['String']['input'];
};


export type MutationUnsetOrderBillingAddressArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type MutationUnsetOrderShippingAddressArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};


export type MutationUpdateBusinessProfileArgs = {
  input: UpdateBusinessInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateCustomerEmailAddressArgs = {
  token: Scalars['String']['input'];
};


export type MutationUpdateCustomerPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateInquiryArgs = {
  input: UpdateInquiryInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateOwnerProductInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateStripeAccountArgs = {
  IBAN: Scalars['String']['input'];
};


export type MutationVerifyCustomerAccountArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type MutationVoteOnReviewArgs = {
  id: Scalars['ID']['input'];
  vote: Scalars['Boolean']['input'];
};

export type NativeAuthInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type NativeAuthStrategyError = ErrorResult & {
  __typename?: 'NativeAuthStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type NativeAuthenticationResult = CurrentUser | InvalidCredentialsError | NativeAuthStrategyError | NotVerifiedError;

export type NegativeQuantityError = ErrorResult & {
  __typename?: 'NegativeQuantityError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type NoActiveOrderError = ErrorResult & {
  __typename?: 'NoActiveOrderError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type NoCommunityAccessError = ErrorResult & {
  __typename?: 'NoCommunityAccessError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotVerifiedError = ErrorResult & {
  __typename?: 'NotVerifiedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type NothingToRefundError = ErrorResult & {
  __typename?: 'NothingToRefundError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  /** @deprecated Chat moved to TalkJS */
  unreadConversationIds: Array<Scalars['ID']['output']>;
  unreadOrderIds: Array<Scalars['ID']['output']>;
  unreadRequests: Scalars['Int']['output'];
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  translations: Array<NotificationPreferenceTranslation>;
};

export type NotificationPreferenceTranslation = {
  __typename?: 'NotificationPreferenceTranslation';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  languageCode: LanguageCode;
};

export type NumberListOperators = {
  inList: Scalars['Float']['input'];
};

export type NumberOperators = {
  between?: InputMaybe<NumberRange>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type NumberRange = {
  end: Scalars['Float']['input'];
  start: Scalars['Float']['input'];
};

export type OpenGraph = {
  __typename?: 'OpenGraph';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type OptInResult = {
  __typename?: 'OptInResult';
  resetToken: Scalars['String']['output'];
};

export type Order = Node & {
  __typename?: 'Order';
  active: Scalars['Boolean']['output'];
  billingAddress?: Maybe<OrderAddress>;
  code: Scalars['String']['output'];
  commission: Scalars['Int']['output'];
  couponCodes: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<OrderCustomFields>;
  customer?: Maybe<Customer>;
  discounts: Array<Discount>;
  fulfillments?: Maybe<Array<Fulfillment>>;
  history: HistoryEntryList;
  id: Scalars['ID']['output'];
  lines: Array<OrderLine>;
  orderPlacedAt?: Maybe<Scalars['DateTime']['output']>;
  payments?: Maybe<Array<Payment>>;
  productTotal: Scalars['Int']['output'];
  promotions: Array<Promotion>;
  serviceFee: Scalars['Int']['output'];
  shipping: Scalars['Money']['output'];
  shippingAddress?: Maybe<OrderAddress>;
  shippingLines: Array<ShippingLine>;
  shippingWithTax: Scalars['Money']['output'];
  state: Scalars['String']['output'];
  subTotal: Scalars['Money']['output'];
  subTotalWithTax: Scalars['Money']['output'];
  surcharges: Array<Surcharge>;
  taxSummary: Array<OrderTaxSummary>;
  total: Scalars['Money']['output'];
  totalQuantity: Scalars['Int']['output'];
  totalWithTax: Scalars['Money']['output'];
  type: OrderType;
  /** @deprecated We are not using this field anymore, but a list of all unread entities instead */
  unread: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated API caching makes this field impossible to use */
  userRelation: UserEntityRelation;
};


export type OrderHistoryArgs = {
  options?: InputMaybe<HistoryEntryListOptions>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Scalars['JSON']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
};

export type OrderAlreadyHasVoucherError = ErrorResult & {
  __typename?: 'OrderAlreadyHasVoucherError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type OrderCustomFields = {
  __typename?: 'OrderCustomFields';
  afterImage?: Maybe<Array<Asset>>;
  afterImageComment?: Maybe<Array<Scalars['String']['output']>>;
  beforeImage?: Maybe<Array<Asset>>;
  beforeImageComment?: Maybe<Array<Scalars['String']['output']>>;
  duration?: Maybe<OrderDuration>;
  ownerProfile?: Maybe<Profile>;
  renterProfile?: Maybe<Profile>;
  requestId?: Maybe<Scalars['String']['output']>;
};

export type OrderDeliveredResult = ImagesCountError | Order | OrderStateTransitionError;

export type OrderDuration = {
  __typename?: 'OrderDuration';
  endDate: Scalars['DateTime']['output'];
  isHourly: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type OrderFilterParameter = {
  _and?: InputMaybe<Array<OrderFilterParameter>>;
  _or?: InputMaybe<Array<OrderFilterParameter>>;
  active?: InputMaybe<BooleanOperators>;
  afterImageComment?: InputMaybe<StringListOperators>;
  beforeImageComment?: InputMaybe<StringListOperators>;
  code?: InputMaybe<StringOperators>;
  commission?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  orderPlacedAt?: InputMaybe<DateOperators>;
  productTotal?: InputMaybe<NumberOperators>;
  requestId?: InputMaybe<StringOperators>;
  serviceFee?: InputMaybe<NumberOperators>;
  shipping?: InputMaybe<NumberOperators>;
  shippingWithTax?: InputMaybe<NumberOperators>;
  state?: InputMaybe<StringOperators>;
  subTotal?: InputMaybe<NumberOperators>;
  subTotalWithTax?: InputMaybe<NumberOperators>;
  total?: InputMaybe<NumberOperators>;
  totalQuantity?: InputMaybe<NumberOperators>;
  totalWithTax?: InputMaybe<NumberOperators>;
  type?: InputMaybe<StringOperators>;
  unread?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userRelation?: InputMaybe<StringOperators>;
};

export enum OrderImagesType {
  AfterImages = 'AFTER_IMAGES',
  BeforeImages = 'BEFORE_IMAGES'
}

export type OrderInterceptorError = ErrorResult & {
  __typename?: 'OrderInterceptorError';
  errorCode: ErrorCode;
  interceptorError: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type OrderLimitError = ErrorResult & {
  __typename?: 'OrderLimitError';
  errorCode: ErrorCode;
  maxItems: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type OrderLine = Node & {
  __typename?: 'OrderLine';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  discountedLinePrice: Scalars['Money']['output'];
  discountedLinePriceWithTax: Scalars['Money']['output'];
  discountedUnitPrice: Scalars['Money']['output'];
  discountedUnitPriceWithTax: Scalars['Money']['output'];
  discounts: Array<Discount>;
  featuredAsset?: Maybe<Asset>;
  fulfillmentLines?: Maybe<Array<FulfillmentLine>>;
  id: Scalars['ID']['output'];
  linePrice: Scalars['Money']['output'];
  linePriceWithTax: Scalars['Money']['output'];
  lineTax: Scalars['Money']['output'];
  order: Order;
  orderPlacedQuantity: Scalars['Int']['output'];
  productVariant: ProductVariant;
  proratedLinePrice: Scalars['Money']['output'];
  proratedLinePriceWithTax: Scalars['Money']['output'];
  proratedUnitPrice: Scalars['Money']['output'];
  proratedUnitPriceWithTax: Scalars['Money']['output'];
  quantity: Scalars['Int']['output'];
  taxLines: Array<TaxLine>;
  taxRate: Scalars['Float']['output'];
  unitPrice: Scalars['Money']['output'];
  unitPriceChangeSinceAdded: Scalars['Money']['output'];
  unitPriceWithTax: Scalars['Money']['output'];
  unitPriceWithTaxChangeSinceAdded: Scalars['Money']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderLineInput = {
  orderLineId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type OrderList = PaginatedList & {
  __typename?: 'OrderList';
  items: Array<Order>;
  totalItems: Scalars['Int']['output'];
};

export type OrderListOptions = {
  byOwner?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OrderFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<OrderSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderModificationError = ErrorResult & {
  __typename?: 'OrderModificationError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type OrderPaymentStateError = ErrorResult & {
  __typename?: 'OrderPaymentStateError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type OrderSortParameter = {
  code?: InputMaybe<SortOrder>;
  commission?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderPlacedAt?: InputMaybe<SortOrder>;
  ownerProfile?: InputMaybe<SortOrder>;
  productTotal?: InputMaybe<SortOrder>;
  renterProfile?: InputMaybe<SortOrder>;
  requestId?: InputMaybe<SortOrder>;
  serviceFee?: InputMaybe<SortOrder>;
  shipping?: InputMaybe<SortOrder>;
  shippingWithTax?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
  subTotalWithTax?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  totalQuantity?: InputMaybe<SortOrder>;
  totalWithTax?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderStateTransitionError = ErrorResult & {
  __typename?: 'OrderStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String']['output'];
  message: Scalars['String']['output'];
  toState: Scalars['String']['output'];
  transitionError: Scalars['String']['output'];
};

export type OrderStatistics = {
  __typename?: 'OrderStatistics';
  id: Scalars['ID']['output'];
  totalCount: Scalars['Int']['output'];
  totalRevenue: Scalars['Int']['output'];
  totalServiceFee: Scalars['Int']['output'];
};

export type OrderTaxSummary = {
  __typename?: 'OrderTaxSummary';
  description: Scalars['String']['output'];
  taxBase: Scalars['Money']['output'];
  taxRate: Scalars['Float']['output'];
  taxTotal: Scalars['Money']['output'];
};

export type OrderTokenActiveOrderInput = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export enum OrderType {
  Aggregate = 'Aggregate',
  Regular = 'Regular',
  Seller = 'Seller'
}

export type PaginatedList = {
  items: Array<Node>;
  totalItems: Scalars['Int']['output'];
};

export type PasswordAlreadySetError = ErrorResult & {
  __typename?: 'PasswordAlreadySetError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type PasswordResetTokenExpiredError = ErrorResult & {
  __typename?: 'PasswordResetTokenExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type PasswordResetTokenInvalidError = ErrorResult & {
  __typename?: 'PasswordResetTokenInvalidError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type PasswordValidationError = ErrorResult & {
  __typename?: 'PasswordValidationError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  validationErrorMessage: Scalars['String']['output'];
};

export type Payment = Node & {
  __typename?: 'Payment';
  amount: Scalars['Money']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  method: Scalars['String']['output'];
  refunds: Array<Refund>;
  state: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentDeclinedError = ErrorResult & {
  __typename?: 'PaymentDeclinedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  paymentErrorMessage: Scalars['String']['output'];
};

export type PaymentFailedError = ErrorResult & {
  __typename?: 'PaymentFailedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  paymentErrorMessage: Scalars['String']['output'];
};

export type PaymentInput = {
  metadata: Scalars['JSON']['input'];
  method: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  checker?: Maybe<ConfigurableOperation>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  handler: ConfigurableOperation;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  translations: Array<PaymentMethodTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PaymentMethodProvider {
  Paypal = 'PAYPAL',
  Stripe = 'STRIPE'
}

export type PaymentMethodQuote = {
  __typename?: 'PaymentMethodQuote';
  code: Scalars['String']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  eligibilityMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isEligible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type PaymentMethodTranslation = {
  __typename?: 'PaymentMethodTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentOrderMismatchError = ErrorResult & {
  __typename?: 'PaymentOrderMismatchError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type PaymentStateTransitionError = ErrorResult & {
  __typename?: 'PaymentStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String']['output'];
  message: Scalars['String']['output'];
  toState: Scalars['String']['output'];
  transitionError: Scalars['String']['output'];
};

export type Payout = Node & {
  __typename?: 'Payout';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderId?: Maybe<Scalars['ID']['output']>;
  payoutJson: PayoutData;
  recipient?: Maybe<User>;
  service: Scalars['String']['output'];
  status: PayoutStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type PayoutData = {
  __typename?: 'PayoutData';
  amount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  payoutId?: Maybe<Scalars['ID']['output']>;
};

export enum PayoutStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export enum Permission {
  AddCommunityMember = 'AddCommunityMember',
  Authenticated = 'Authenticated',
  BulkCreateVoucher = 'BulkCreateVoucher',
  CreateAdministrator = 'CreateAdministrator',
  CreateAsset = 'CreateAsset',
  CreateCatalog = 'CreateCatalog',
  CreateChannel = 'CreateChannel',
  CreateCollection = 'CreateCollection',
  CreateCommunity = 'CreateCommunity',
  CreateCountry = 'CreateCountry',
  CreateCustomer = 'CreateCustomer',
  CreateCustomerGroup = 'CreateCustomerGroup',
  CreateFacet = 'CreateFacet',
  CreateNewsletter = 'CreateNewsletter',
  CreateOrder = 'CreateOrder',
  CreatePaymentMethod = 'CreatePaymentMethod',
  CreatePayout = 'CreatePayout',
  CreatePreference = 'CreatePreference',
  CreateProduct = 'CreateProduct',
  CreateProductTemplate = 'CreateProductTemplate',
  CreateProductTemplateExtra = 'CreateProductTemplateExtra',
  CreateProfile = 'CreateProfile',
  CreatePromotion = 'CreatePromotion',
  CreatePushNotification = 'CreatePushNotification',
  CreateReleaseNote = 'CreateReleaseNote',
  CreateRequest = 'CreateRequest',
  CreateSeller = 'CreateSeller',
  CreateSettings = 'CreateSettings',
  CreateShippingMethod = 'CreateShippingMethod',
  CreateStockLocation = 'CreateStockLocation',
  CreateSystem = 'CreateSystem',
  CreateTag = 'CreateTag',
  CreateTaxCategory = 'CreateTaxCategory',
  CreateTaxRate = 'CreateTaxRate',
  CreateUserDevices = 'CreateUserDevices',
  CreateVoucher = 'CreateVoucher',
  CreateZone = 'CreateZone',
  CurateSearch = 'CurateSearch',
  DeleteAdministrator = 'DeleteAdministrator',
  DeleteAsset = 'DeleteAsset',
  DeleteCatalog = 'DeleteCatalog',
  DeleteChannel = 'DeleteChannel',
  DeleteCollection = 'DeleteCollection',
  DeleteCommunity = 'DeleteCommunity',
  DeleteCountry = 'DeleteCountry',
  DeleteCustomer = 'DeleteCustomer',
  DeleteCustomerGroup = 'DeleteCustomerGroup',
  DeleteFacet = 'DeleteFacet',
  DeleteNewsletter = 'DeleteNewsletter',
  DeleteOrder = 'DeleteOrder',
  DeletePaymentMethod = 'DeletePaymentMethod',
  DeletePayout = 'DeletePayout',
  DeletePreference = 'DeletePreference',
  DeleteProduct = 'DeleteProduct',
  DeleteProductTemplate = 'DeleteProductTemplate',
  DeleteProductTemplateExtra = 'DeleteProductTemplateExtra',
  DeleteProfile = 'DeleteProfile',
  DeletePromotion = 'DeletePromotion',
  DeletePushNotification = 'DeletePushNotification',
  DeleteReleaseNote = 'DeleteReleaseNote',
  DeleteRequest = 'DeleteRequest',
  DeleteSeller = 'DeleteSeller',
  DeleteSettings = 'DeleteSettings',
  DeleteShippingMethod = 'DeleteShippingMethod',
  DeleteStockLocation = 'DeleteStockLocation',
  DeleteSystem = 'DeleteSystem',
  DeleteTag = 'DeleteTag',
  DeleteTaxCategory = 'DeleteTaxCategory',
  DeleteTaxRate = 'DeleteTaxRate',
  DeleteUserDevices = 'DeleteUserDevices',
  DeleteVoucher = 'DeleteVoucher',
  DeleteZone = 'DeleteZone',
  Invoice = 'Invoice',
  InvoiceGenerate = 'InvoiceGenerate',
  OpenAiConfig = 'OpenAiConfig',
  Owner = 'Owner',
  Public = 'Public',
  ReadAdministrator = 'ReadAdministrator',
  ReadAsset = 'ReadAsset',
  ReadCatalog = 'ReadCatalog',
  ReadChannel = 'ReadChannel',
  ReadCollection = 'ReadCollection',
  ReadCommunity = 'ReadCommunity',
  ReadCountry = 'ReadCountry',
  ReadCustomer = 'ReadCustomer',
  ReadCustomerGroup = 'ReadCustomerGroup',
  ReadDevice = 'ReadDevice',
  ReadFacet = 'ReadFacet',
  ReadIdentity = 'ReadIdentity',
  ReadNewsletter = 'ReadNewsletter',
  ReadOrder = 'ReadOrder',
  ReadPaymentMethod = 'ReadPaymentMethod',
  ReadPayout = 'ReadPayout',
  ReadPreference = 'ReadPreference',
  ReadProduct = 'ReadProduct',
  ReadProductTemplate = 'ReadProductTemplate',
  ReadProductTemplateExtra = 'ReadProductTemplateExtra',
  ReadProfile = 'ReadProfile',
  ReadPromotion = 'ReadPromotion',
  ReadPushNotification = 'ReadPushNotification',
  ReadReleaseNote = 'ReadReleaseNote',
  ReadRequest = 'ReadRequest',
  ReadSchufaData = 'ReadSchufaData',
  ReadSchufaRecord = 'ReadSchufaRecord',
  ReadSeller = 'ReadSeller',
  ReadSettings = 'ReadSettings',
  ReadShippingMethod = 'ReadShippingMethod',
  ReadStockLocation = 'ReadStockLocation',
  ReadSystem = 'ReadSystem',
  ReadTag = 'ReadTag',
  ReadTaxCategory = 'ReadTaxCategory',
  ReadTaxRate = 'ReadTaxRate',
  ReadUserDevices = 'ReadUserDevices',
  ReadVoucher = 'ReadVoucher',
  ReadZone = 'ReadZone',
  RefundOrder = 'RefundOrder',
  RemoveCommunityMember = 'RemoveCommunityMember',
  RevokeIdentification = 'RevokeIdentification',
  SendNewsletter = 'SendNewsletter',
  SendNotification = 'SendNotification',
  SetWebhook = 'SetWebhook',
  SuperAdmin = 'SuperAdmin',
  TransferOwnership = 'TransferOwnership',
  TriggerSchufa = 'TriggerSchufa',
  UpdateAdministrator = 'UpdateAdministrator',
  UpdateAsset = 'UpdateAsset',
  UpdateCatalog = 'UpdateCatalog',
  UpdateChannel = 'UpdateChannel',
  UpdateCollection = 'UpdateCollection',
  UpdateCommission = 'UpdateCommission',
  UpdateCommunity = 'UpdateCommunity',
  UpdateCountry = 'UpdateCountry',
  UpdateCustomer = 'UpdateCustomer',
  UpdateCustomerGroup = 'UpdateCustomerGroup',
  UpdateFacet = 'UpdateFacet',
  UpdateGlobalSettings = 'UpdateGlobalSettings',
  UpdateNewsletter = 'UpdateNewsletter',
  UpdateOrder = 'UpdateOrder',
  UpdatePaymentMethod = 'UpdatePaymentMethod',
  UpdatePayout = 'UpdatePayout',
  UpdatePreference = 'UpdatePreference',
  UpdateProduct = 'UpdateProduct',
  UpdateProductTemplate = 'UpdateProductTemplate',
  UpdateProductTemplateExtra = 'UpdateProductTemplateExtra',
  UpdateProfile = 'UpdateProfile',
  UpdatePromotion = 'UpdatePromotion',
  UpdatePushNotification = 'UpdatePushNotification',
  UpdateReleaseNote = 'UpdateReleaseNote',
  UpdateRequest = 'UpdateRequest',
  UpdateSeller = 'UpdateSeller',
  UpdateSettings = 'UpdateSettings',
  UpdateShippingMethod = 'UpdateShippingMethod',
  UpdateStockLocation = 'UpdateStockLocation',
  UpdateSystem = 'UpdateSystem',
  UpdateTag = 'UpdateTag',
  UpdateTaxCategory = 'UpdateTaxCategory',
  UpdateTaxRate = 'UpdateTaxRate',
  UpdateUserDevices = 'UpdateUserDevices',
  UpdateVoucher = 'UpdateVoucher',
  UpdateZone = 'UpdateZone',
  VerifyCustomer = 'VerifyCustomer'
}

export enum Platform {
  App = 'APP',
  Web = 'WEB'
}

export type Preference = Node & {
  __typename?: 'Preference';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  tooltip: Scalars['String']['output'];
  translations: Array<PreferenceTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PreferenceFilterParameter = {
  _and?: InputMaybe<Array<PreferenceFilterParameter>>;
  _or?: InputMaybe<Array<PreferenceFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  tooltip?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PreferenceList = PaginatedList & {
  __typename?: 'PreferenceList';
  items: Array<Preference>;
  totalItems: Scalars['Int']['output'];
};

export type PreferenceListOptions = {
  filter?: InputMaybe<PreferenceFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PreferenceSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type PreferenceSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  tooltip?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PreferenceTranslation = {
  __typename?: 'PreferenceTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  tooltip: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PriceRange = {
  __typename?: 'PriceRange';
  max: Scalars['Money']['output'];
  min: Scalars['Money']['output'];
};

export type PriceRangeInput = {
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
};

export type Product = Node & {
  __typename?: 'Product';
  assets: Array<Asset>;
  category?: Maybe<FacetValue>;
  channels: Array<Channel>;
  collections: Array<Collection>;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<ProductCustomFields>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  location: UploadLocation;
  name: Scalars['String']['output'];
  optionGroups: Array<ProductOptionGroup>;
  owner?: Maybe<Profile>;
  slug: Scalars['String']['output'];
  subCategory?: Maybe<FacetValue>;
  translations: Array<ProductTranslation>;
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated API caching makes this field impossible to use */
  userRelation: UserEntityRelation;
  variantList: ProductVariantList;
  variants: Array<ProductVariant>;
};


export type ProductVariantListArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type ProductBlockedOrBookedError = ErrorResult & {
  __typename?: 'ProductBlockedOrBookedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type ProductClaimPeriod = Node & {
  __typename?: 'ProductClaimPeriod';
  context: DurationContextType;
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductClaimPeriodFilterParameter = {
  _and?: InputMaybe<Array<ProductClaimPeriodFilterParameter>>;
  _or?: InputMaybe<Array<ProductClaimPeriodFilterParameter>>;
  context?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  endDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  startDate?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductClaimPeriodList = PaginatedList & {
  __typename?: 'ProductClaimPeriodList';
  items: Array<ProductClaimPeriod>;
  totalItems: Scalars['Int']['output'];
};

export type ProductClaimPeriodListOptions = {
  filter?: InputMaybe<ProductClaimPeriodFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductClaimPeriodSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductClaimPeriodSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductCustomFields = {
  __typename?: 'ProductCustomFields';
  allowIndexing?: Maybe<Scalars['Boolean']['output']>;
  delivery?: Maybe<Scalars['String']['output']>;
  insurance?: Maybe<Scalars['Boolean']['output']>;
  isHourly?: Maybe<Scalars['Boolean']['output']>;
  purchaseDate?: Maybe<Scalars['DateTime']['output']>;
  purchasePrice?: Maybe<Scalars['Float']['output']>;
  reviewCount?: Maybe<Scalars['Float']['output']>;
  reviewRating?: Maybe<Scalars['Float']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  shippingFee?: Maybe<Scalars['Int']['output']>;
  template?: Maybe<ProductTemplate>;
};

export enum ProductDeliveryOptions {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
  PickupAndDelivery = 'PICKUP_AND_DELIVERY'
}

export type ProductFilterParameter = {
  _and?: InputMaybe<Array<ProductFilterParameter>>;
  _or?: InputMaybe<Array<ProductFilterParameter>>;
  allowIndexing?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  delivery?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  insurance?: InputMaybe<BooleanOperators>;
  isHourly?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  purchaseDate?: InputMaybe<DateOperators>;
  purchasePrice?: InputMaybe<NumberOperators>;
  reviewCount?: InputMaybe<NumberOperators>;
  reviewRating?: InputMaybe<NumberOperators>;
  serialNumber?: InputMaybe<StringOperators>;
  shippingFee?: InputMaybe<NumberOperators>;
  slug?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userRelation?: InputMaybe<StringOperators>;
};

export type ProductList = PaginatedList & {
  __typename?: 'ProductList';
  items: Array<Product>;
  totalItems: Scalars['Int']['output'];
};

export type ProductListOptions = {
  filter?: InputMaybe<ProductFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductOption = Node & {
  __typename?: 'ProductOption';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  group: ProductOptionGroup;
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  translations: Array<ProductOptionTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductOptionGroup = Node & {
  __typename?: 'ProductOptionGroup';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  options: Array<ProductOption>;
  translations: Array<ProductOptionGroupTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductOptionGroupTranslation = {
  __typename?: 'ProductOptionGroupTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductOptionTranslation = {
  __typename?: 'ProductOptionTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductPriceSet = {
  __typename?: 'ProductPriceSet';
  day: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type ProductPricesByDiscounts = {
  __typename?: 'ProductPricesByDiscounts';
  days: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type ProductReview = Node & {
  __typename?: 'ProductReview';
  author?: Maybe<Customer>;
  authorLocation?: Maybe<Scalars['String']['output']>;
  authorName: Scalars['String']['output'];
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  downvotes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  product: Product;
  rating: Scalars['Float']['output'];
  response?: Maybe<Scalars['String']['output']>;
  responseCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  state: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  upvotes: Scalars['Int']['output'];
  /** @deprecated API caching makes this field impossible to use */
  userRelation: UserEntityRelation;
};

export type ProductReviewHistogramItem = {
  __typename?: 'ProductReviewHistogramItem';
  bin: Scalars['Int']['output'];
  frequency: Scalars['Int']['output'];
};

export type ProductReviewList = PaginatedList & {
  __typename?: 'ProductReviewList';
  items: Array<ProductReview>;
  totalItems: Scalars['Int']['output'];
};

export type ProductReviewListOptions = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductSortParameter = {
  allowIndexing?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  delivery?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  insurance?: InputMaybe<SortOrder>;
  isHourly?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  purchaseDate?: InputMaybe<SortOrder>;
  purchasePrice?: InputMaybe<SortOrder>;
  reviewCount?: InputMaybe<SortOrder>;
  reviewRating?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  shippingFee?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  template?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductTemplate = Node & {
  __typename?: 'ProductTemplate';
  assets: Array<Asset>;
  category: FacetValue;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  faqs: Array<ProductTemplateFaq>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceRecommendation: Scalars['Int']['output'];
  products: ProductList;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  specs: Array<ProductTemplateSpecs>;
  subCategory: FacetValue;
  translations: Array<ProductTemplateTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductTemplateProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};

export type ProductTemplateFaq = Node & {
  __typename?: 'ProductTemplateFaq';
  answer: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductTemplateFilterParameter = {
  _and?: InputMaybe<Array<ProductTemplateFilterParameter>>;
  _or?: InputMaybe<Array<ProductTemplateFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  priceRecommendation?: InputMaybe<NumberOperators>;
  slug?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductTemplateList = PaginatedList & {
  __typename?: 'ProductTemplateList';
  items: Array<ProductTemplate>;
  totalItems: Scalars['Int']['output'];
};

export type ProductTemplateListOptions = {
  filter?: InputMaybe<ProductTemplateFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  key?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductTemplateSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductTemplateSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceRecommendation?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductTemplateSpecs = Node & {
  __typename?: 'ProductTemplateSpecs';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductTemplateTranslation = Node & {
  __typename?: 'ProductTemplateTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductTranslation = {
  __typename?: 'ProductTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductVariant = Node & {
  __typename?: 'ProductVariant';
  assets: Array<Asset>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<ProductVariantCustomFields>;
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  options: Array<ProductOption>;
  price: Scalars['Money']['output'];
  priceWithTax: Scalars['Money']['output'];
  prices: Array<ProductVariantPrice>;
  product: Product;
  productId: Scalars['ID']['output'];
  sku: Scalars['String']['output'];
  stockLevel: Scalars['String']['output'];
  taxCategory: TaxCategory;
  taxRateApplied: TaxRate;
  translations: Array<ProductVariantTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductVariantCustomFields = {
  __typename?: 'ProductVariantCustomFields';
  tieredPricing?: Maybe<ProductVariantPriceTiers>;
};

export type ProductVariantFilterParameter = {
  _and?: InputMaybe<Array<ProductVariantFilterParameter>>;
  _or?: InputMaybe<Array<ProductVariantFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  price?: InputMaybe<NumberOperators>;
  priceWithTax?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  sku?: InputMaybe<StringOperators>;
  stockLevel?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductVariantList = PaginatedList & {
  __typename?: 'ProductVariantList';
  items: Array<ProductVariant>;
  totalItems: Scalars['Int']['output'];
};

export type ProductVariantListOptions = {
  filter?: InputMaybe<ProductVariantFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductVariantSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductVariantPrice = {
  __typename?: 'ProductVariantPrice';
  channel: Channel;
  channelId: Scalars['ID']['output'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<ProductVariantPriceCustomFields>;
  price: Scalars['Money']['output'];
};

export type ProductVariantPriceCustomFields = {
  __typename?: 'ProductVariantPriceCustomFields';
  tieredPricing?: Maybe<ProductVariantPriceTiers>;
};

export type ProductVariantPriceTiers = Node & {
  __typename?: 'ProductVariantPriceTiers';
  autoUpdate: Scalars['Boolean']['output'];
  channel: Channel;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  prices: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductVariantSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceWithTax?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  stockLevel?: InputMaybe<SortOrder>;
  tieredPricing?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductVariantTranslation = {
  __typename?: 'ProductVariantTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProducttemplateResponse = {
  __typename?: 'ProducttemplateResponse';
  items: Array<ProducttemplateResult>;
  searchTimeMs: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type ProducttemplateResult = {
  __typename?: 'ProducttemplateResult';
  categoryId: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  featuredAssetPreview?: Maybe<Scalars['String']['output']>;
  highlights: Array<ResultHighlight>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priceRecommendation: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  subCategoryId: Scalars['String']['output'];
  subCategoryName: Scalars['String']['output'];
};

export type Profile = Node & {
  __typename?: 'Profile';
  business?: Maybe<BusinessProfile>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID']['output'];
  links: Array<SocialLink>;
  name: Scalars['String']['output'];
  statistics: ProfileStatistics;
  type: ProfileType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  /** @deprecated API caching makes this field impossible to use */
  userRelation: UserEntityRelation;
  verificationStatus: Scalars['Boolean']['output'];
};

export type ProfileStatistics = Node & {
  __typename?: 'ProfileStatistics';
  averageRating: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  feedbackCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lending: Scalars['Int']['output'];
  renting: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum ProfileType {
  Admin = 'ADMIN',
  Business = 'BUSINESS',
  Personal = 'PERSONAL'
}

export type Promotion = Node & {
  __typename?: 'Promotion';
  actions: Array<ConfigurableOperation>;
  conditions: Array<ConfigurableOperation>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  perCustomerUsageLimit?: Maybe<Scalars['Int']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  translations: Array<PromotionTranslation>;
  updatedAt: Scalars['DateTime']['output'];
  usageLimit?: Maybe<Scalars['Int']['output']>;
};

export type PromotionList = PaginatedList & {
  __typename?: 'PromotionList';
  items: Array<Promotion>;
  totalItems: Scalars['Int']['output'];
};

export type PromotionTranslation = {
  __typename?: 'PromotionTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Province = Node & Region & {
  __typename?: 'Province';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']['output']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProvinceList = PaginatedList & {
  __typename?: 'ProvinceList';
  items: Array<Province>;
  totalItems: Scalars['Int']['output'];
};

export type QuantityTooGreatError = ErrorResult & {
  __typename?: 'QuantityTooGreatError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  activeChannel: Channel;
  activeCommunities: Array<Community>;
  activeCommunityInvites: CommunityInvitationList;
  activeCustomer?: Maybe<Customer>;
  activeNotificationPreferences: Array<Scalars['String']['output']>;
  activeOrder?: Maybe<Order>;
  activeProfile?: Maybe<Profile>;
  activeUserInterests?: Maybe<UserInterests>;
  activeUserProducts: ProductList;
  asset: Asset;
  assets: AssetList;
  availableCountries: Array<Country>;
  calculatePrice: Scalars['Int']['output'];
  calculatePrices: Array<ProductPriceSet>;
  categories: Array<FacetValue>;
  collection?: Maybe<Collection>;
  collections: CollectionList;
  communities: CommunityList;
  community: Community;
  communityInvitations: CommunityInvitationList;
  communityMembers: CommunityMemberList;
  /** @deprecated Will be handled with TalkJS */
  conversation?: Maybe<Conversation>;
  /** @deprecated Will be handled with TalkJS */
  conversations: ConversationList;
  defaultUploadLocation?: Maybe<UploadLocation>;
  eligiblePaymentMethods: Array<PaymentMethodQuote>;
  eligibleShippingMethods: Array<ShippingMethodQuote>;
  facet?: Maybe<Facet>;
  facets: FacetList;
  favorites: ProductList;
  feedback?: Maybe<CustomerFeedback>;
  feedbacks: CustomerFeedbackList;
  generateProductDescription: GeneratedProductDescription;
  /** @deprecated Use unreadMarkers instead */
  getNotifications: Notification;
  getPaymentClientSecret: GetPaymentClientSecretResult;
  getUserBankAccount?: Maybe<BankAccount>;
  inquiries: InquiryList;
  inquiry?: Maybe<Inquiry>;
  invoice?: Maybe<Invoice>;
  invoiceByOrder?: Maybe<Invoice>;
  invoices: InvoiceList;
  me?: Maybe<CurrentUser>;
  migrationUser?: Maybe<MigratedUser>;
  nextOrderStates: Array<Scalars['String']['output']>;
  notificationPreferences: Array<NotificationPreference>;
  order?: Maybe<Order>;
  orderByCode?: Maybe<Order>;
  orderNeedsFeedback: Scalars['Boolean']['output'];
  orderWithFeedbackRemaining?: Maybe<Order>;
  orders: OrderList;
  ownerStats: OrderStatistics;
  preferences: PreferenceList;
  product?: Maybe<Product>;
  productClaimPeriods: ProductClaimPeriodList;
  productIsFavorite: Scalars['Boolean']['output'];
  productTemplate: ProductTemplate;
  productTemplates: ProductTemplateList;
  products: ProductList;
  profile?: Maybe<Profile>;
  registeredDevices: UserDeviceList;
  releaseNote?: Maybe<ReleaseNote>;
  releaseNotes: ReleaseNoteList;
  request?: Maybe<Request>;
  requests: RequestList;
  search: SearchResponse;
  searchCommunity: CommunityResponse;
  searchExternal: Array<ExternalIndexResponse>;
  searchProductTemplates: ProducttemplateResponse;
  subcategories: Array<FacetValue>;
  talkSignature: Scalars['String']['output'];
  unreadMarkers: Array<UnreadMarker>;
  uploadLocation?: Maybe<UploadLocation>;
  uploadLocations?: Maybe<Array<UploadLocation>>;
  userProducts: ProductList;
  userVerificationStatus?: Maybe<SumSubResult>;
  vouchers: VoucherList;
};


export type QueryActiveCommunityInvitesArgs = {
  options?: InputMaybe<CommunityInvitationListOptions>;
};


export type QueryActiveOrderArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type QueryActiveUserProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryAssetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAssetsArgs = {
  options?: InputMaybe<AssetListOptions>;
};


export type QueryCalculatePriceArgs = {
  days: Scalars['Int']['input'];
  variantId: Scalars['ID']['input'];
};


export type QueryCalculatePricesArgs = {
  days: Array<Scalars['Int']['input']>;
  variantId: Scalars['ID']['input'];
};


export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
};


export type QueryCommunitiesArgs = {
  options?: InputMaybe<CommunityListOptions>;
};


export type QueryCommunityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCommunityInvitationsArgs = {
  id: Scalars['ID']['input'];
  options?: InputMaybe<CommunityInvitationListOptions>;
};


export type QueryCommunityMembersArgs = {
  id: Scalars['ID']['input'];
  options?: InputMaybe<CommunityMemberListOptions>;
};


export type QueryConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type QueryConversationsArgs = {
  options?: InputMaybe<ConversationListOptions>;
};


export type QueryEligiblePaymentMethodsArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type QueryEligibleShippingMethodsArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type QueryFacetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFacetsArgs = {
  options?: InputMaybe<FacetListOptions>;
};


export type QueryFavoritesArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeedbacksArgs = {
  options?: InputMaybe<CustomerFeedbackListOptions>;
  profileId: Scalars['ID']['input'];
};


export type QueryGenerateProductDescriptionArgs = {
  input: GenerateProductDescriptionInput;
};


export type QueryGetPaymentClientSecretArgs = {
  input: GetPaymentClientSecretInput;
};


export type QueryInquiriesArgs = {
  options?: InputMaybe<InquiryListOptions>;
};


export type QueryInquiryArgs = {
  inquiryId: Scalars['ID']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceByOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  options?: InputMaybe<InvoiceListOptions>;
};


export type QueryMigrationUserArgs = {
  userToken: Scalars['String']['input'];
};


export type QueryNextOrderStatesArgs = {
  activeOrderInput?: InputMaybe<ActiveOrderInput>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryOrderNeedsFeedbackArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
};


export type QueryPreferencesArgs = {
  options?: InputMaybe<PreferenceListOptions>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductClaimPeriodsArgs = {
  options?: InputMaybe<ProductClaimPeriodListOptions>;
  productId: Scalars['ID']['input'];
};


export type QueryProductIsFavoriteArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryProductTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductTemplatesArgs = {
  options?: InputMaybe<ProductTemplateListOptions>;
};


export type QueryProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRegisteredDevicesArgs = {
  options?: InputMaybe<UserDeviceListOptions>;
};


export type QueryReleaseNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReleaseNotesArgs = {
  options?: InputMaybe<ReleaseNoteListOptions>;
};


export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRequestsArgs = {
  options?: InputMaybe<RequestListOptions>;
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySearchCommunityArgs = {
  input: ExternalSearchScopedInput;
};


export type QuerySearchExternalArgs = {
  input: ExternalSearchInput;
};


export type QuerySearchProductTemplatesArgs = {
  input: ExternalSearchScopedInput;
};


export type QueryUploadLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
  profileId: Scalars['ID']['input'];
};


export type QueryVouchersArgs = {
  options?: InputMaybe<VoucherListOptions>;
};

export type RefreshCustomerVerificationResult = NativeAuthStrategyError | Success;

export type Refund = Node & {
  __typename?: 'Refund';
  adjustment: Scalars['Money']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  items: Scalars['Money']['output'];
  lines: Array<RefundLine>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  shipping: Scalars['Money']['output'];
  state: Scalars['String']['output'];
  total: Scalars['Money']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RefundLine = {
  __typename?: 'RefundLine';
  orderLine: OrderLine;
  orderLineId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  refund: Refund;
  refundId: Scalars['ID']['output'];
};

export type RefundOrderResult = AlreadyRefundedError | MultipleOrderError | NothingToRefundError | OrderStateTransitionError | PaymentOrderMismatchError | QuantityTooGreatError | Refund | RefundOrderStateError | RefundStateTransitionError;

export type RefundOrderStateError = ErrorResult & {
  __typename?: 'RefundOrderStateError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
  orderState: Scalars['String']['output'];
};

export type RefundStateTransitionError = ErrorResult & {
  __typename?: 'RefundStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String']['output'];
  message: Scalars['String']['output'];
  toState: Scalars['String']['output'];
  transitionError: Scalars['String']['output'];
};

export type Region = {
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']['output']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RegionTranslation = {
  __typename?: 'RegionTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RegisterCustomerAccountResult = MissingPasswordError | NativeAuthStrategyError | PasswordValidationError | Success;

export type RegisterCustomerCustomFieldsInput = {
  allowNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCustomerInput = {
  business?: InputMaybe<CreateBusinessInput>;
  customFields?: InputMaybe<RegisterCustomerCustomFieldsInput>;
  emailAddress: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: ProfileType;
};

export type RegisterUserDeviceInput = {
  deviceName?: InputMaybe<Scalars['String']['input']>;
  deviceToken: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  platform: Scalars['String']['input'];
};

export type RelationCustomFieldConfig = CustomField & {
  __typename?: 'RelationCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  entity: Scalars['String']['output'];
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  scalarFields: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type ReleaseNote = Node & {
  __typename?: 'ReleaseNote';
  backend: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  translations: Array<ReleaseNoteTranslation>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

export type ReleaseNoteFilterParameter = {
  _and?: InputMaybe<Array<ReleaseNoteFilterParameter>>;
  _or?: InputMaybe<Array<ReleaseNoteFilterParameter>>;
  backend?: InputMaybe<StringOperators>;
  content?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  title?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  version?: InputMaybe<StringOperators>;
};

export type ReleaseNoteList = PaginatedList & {
  __typename?: 'ReleaseNoteList';
  items: Array<ReleaseNote>;
  totalItems: Scalars['Int']['output'];
};

export type ReleaseNoteListOptions = {
  filter?: InputMaybe<ReleaseNoteFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReleaseNoteSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ReleaseNoteSortParameter = {
  backend?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type ReleaseNoteTranslation = {
  __typename?: 'ReleaseNoteTranslation';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RemoveOrderItemsResult = Order | OrderInterceptorError | OrderModificationError;

export type RemoveProductFromChannelInput = {
  channels: Array<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};

export type Request = Node & {
  __typename?: 'Request';
  commission: Scalars['Int']['output'];
  conversationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dayDiscount: Scalars['Int']['output'];
  days: Scalars['Int']['output'];
  deliveryMethod?: Maybe<ShippingMethod>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isHourly: Scalars['Boolean']['output'];
  ownerProfile: Profile;
  productVariant: ProductVariant;
  renterProfile: Profile;
  serviceFee: Scalars['Int']['output'];
  shipping: Scalars['Int']['output'];
  shippingAddress?: Maybe<Address>;
  startDate: Scalars['DateTime']['output'];
  status: StatusType;
  subTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  /** @deprecated We are not using this field anymore, but a list of all unread entities instead */
  unread: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This dynamic property prevents proper caching */
  userRelation: UserEntityRelation;
};

export type RequestCollisionError = ErrorResult & {
  __typename?: 'RequestCollisionError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type RequestFilterParameter = {
  _and?: InputMaybe<Array<RequestFilterParameter>>;
  _or?: InputMaybe<Array<RequestFilterParameter>>;
  commission?: InputMaybe<NumberOperators>;
  conversationId?: InputMaybe<IdOperators>;
  createdAt?: InputMaybe<DateOperators>;
  dayDiscount?: InputMaybe<NumberOperators>;
  days?: InputMaybe<NumberOperators>;
  endDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isHourly?: InputMaybe<BooleanOperators>;
  ownerProfileId?: InputMaybe<IdOperators>;
  renterProfileId?: InputMaybe<IdOperators>;
  serviceFee?: InputMaybe<NumberOperators>;
  shipping?: InputMaybe<NumberOperators>;
  startDate?: InputMaybe<DateOperators>;
  status?: InputMaybe<StringOperators>;
  subTotal?: InputMaybe<NumberOperators>;
  total?: InputMaybe<NumberOperators>;
  unread?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userRelation?: InputMaybe<StringOperators>;
};

export type RequestList = PaginatedList & {
  __typename?: 'RequestList';
  items: Array<Request>;
  totalItems: Scalars['Int']['output'];
};

export type RequestListOptions = {
  byOwner?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<RequestFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<RequestSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestPasswordResetResult = NativeAuthStrategyError | Success;

export type RequestRefundInput = {
  orderId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type RequestShippingAddress = {
  __typename?: 'RequestShippingAddress';
  address: Scalars['String']['output'];
  cityName: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type RequestShippingAddressInput = {
  address: Scalars['String']['input'];
  cityName: Scalars['String']['input'];
  countryName: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type RequestSortParameter = {
  commission?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dayDiscount?: InputMaybe<SortOrder>;
  days?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceFee?: InputMaybe<SortOrder>;
  shipping?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RequestUpdateCustomerEmailAddressResult = EmailAddressConflictError | InvalidCredentialsError | NativeAuthStrategyError | Success;

export type ResetPasswordResult = CurrentUser | NativeAuthStrategyError | NotVerifiedError | PasswordResetTokenExpiredError | PasswordResetTokenInvalidError | PasswordValidationError;

export type ResultHighlight = {
  __typename?: 'ResultHighlight';
  field: Scalars['String']['output'];
  matchedTokens: Array<Scalars['String']['output']>;
  snippet: Scalars['String']['output'];
};

export type ReturnOrderInput = {
  assetComment?: InputMaybe<Scalars['String']['input']>;
  assetIds: Array<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
};

export type Role = Node & {
  __typename?: 'Role';
  channels: Array<Channel>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions: Array<Permission>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RoleList = PaginatedList & {
  __typename?: 'RoleList';
  items: Array<Role>;
  totalItems: Scalars['Int']['output'];
};

export type Seo = Node & {
  __typename?: 'SEO';
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  noFollow?: Maybe<Scalars['Boolean']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  openGraph?: Maybe<OpenGraph>;
  title?: Maybe<Scalars['String']['output']>;
  twitterCard?: Maybe<TwitterCard>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SearchInput = {
  applyCurations?: InputMaybe<Scalars['Boolean']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionSlug?: InputMaybe<Scalars['String']['input']>;
  facetValueFilters?: InputMaybe<Array<FacetValueFilterInput>>;
  facetsFilter?: InputMaybe<FacetsFilterInput>;
  filterBy?: InputMaybe<Array<Scalars['String']['input']>>;
  groupByProduct?: InputMaybe<Scalars['Boolean']['input']>;
  inStock?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  logAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  maxFacetValues?: InputMaybe<Scalars['Int']['input']>;
  prefixMode?: InputMaybe<Scalars['Boolean']['input']>;
  priceRange?: InputMaybe<PriceRangeInput>;
  priceRangeWithTax?: InputMaybe<PriceRangeInput>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SearchResultSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  topCollectionsFromTop?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchListViewedEventInput = {
  queryId: Scalars['String']['input'];
};

export type SearchLocation = Node & {
  __typename?: 'SearchLocation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  radius: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SearchReindexResponse = {
  __typename?: 'SearchReindexResponse';
  success: Scalars['Boolean']['output'];
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  cacheIdentifier?: Maybe<SearchResponseCacheIdentifier>;
  collections: Array<CollectionResult>;
  facetCounts: Array<FacetCountData>;
  facetValues: Array<FacetValueResult>;
  facets: Array<FacetResult>;
  items: Array<SearchResult>;
  prices: SearchResponsePriceData;
  queryId?: Maybe<Scalars['String']['output']>;
  topCollections: Array<Maybe<TopCollectionResult>>;
  totalItems: Scalars['Int']['output'];
};

export type SearchResponseCacheIdentifier = {
  __typename?: 'SearchResponseCacheIdentifier';
  collectionSlug?: Maybe<Scalars['String']['output']>;
};

export type SearchResponsePriceData = {
  __typename?: 'SearchResponsePriceData';
  range: PriceRange;
  rangeWithTax: PriceRange;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  collectionIds: Array<Scalars['ID']['output']>;
  collectionSlugs: Array<Scalars['String']['output']>;
  currencyCode: CurrencyCode;
  customMappings: SearchResultCustomMappings;
  description: Scalars['String']['output'];
  facetIds: Array<Scalars['ID']['output']>;
  facetValueIds: Array<Scalars['ID']['output']>;
  geoDistance?: Maybe<Array<GeoDistance>>;
  highlights: Array<ResultHighlight>;
  id: Scalars['String']['output'];
  inStock: Scalars['Boolean']['output'];
  location?: Maybe<UploadLocation>;
  price: SearchResultPrice;
  priceWithTax: SearchResultPrice;
  productAsset?: Maybe<SearchResultAsset>;
  productId: Scalars['ID']['output'];
  productName: Scalars['String']['output'];
  productVariantAsset?: Maybe<SearchResultAsset>;
  productVariantId: Scalars['ID']['output'];
  productVariantName: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type SearchResultAsset = {
  __typename?: 'SearchResultAsset';
  focalPoint?: Maybe<Coordinate>;
  id: Scalars['ID']['output'];
  preview: Scalars['String']['output'];
};

export type SearchResultClickedEventInput = {
  position: Scalars['Int']['input'];
  queryId: Scalars['String']['input'];
  resultId: Scalars['String']['input'];
};

export type SearchResultCustomMappings = {
  __typename?: 'SearchResultCustomMappings';
  availableChannelIds?: Maybe<Array<Scalars['String']['output']>>;
  availableChannelName?: Maybe<Array<Scalars['String']['output']>>;
  basePrice: Scalars['Int']['output'];
  category?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  hasShipping: Scalars['Boolean']['output'];
  location: Geopoint;
  locationZip?: Maybe<Scalars['String']['output']>;
  ownerBusinessName?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['String']['output'];
  ownerProfileId: Scalars['String']['output'];
  ownerProfileName: Scalars['String']['output'];
  ownerProfilePreview: Scalars['String']['output'];
  ownerType: Scalars['String']['output'];
  reviewCount: Scalars['Int']['output'];
  reviewRating: Scalars['Int']['output'];
  shippingFee: Scalars['Int']['output'];
  subCategory?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
  templateSlug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type SearchResultPrice = PriceRange | SinglePrice;

export type SearchResultSortParameter = {
  location?: InputMaybe<GeopointSort>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  shippingFee?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Seller = Node & {
  __typename?: 'Seller';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SetCustomerForOrderResult = AlreadyLoggedInError | EmailAddressConflictError | GuestCheckoutError | NoActiveOrderError | Order;

export type SetOrderShippingMethodResult = IneligibleShippingMethodError | NoActiveOrderError | Order | OrderModificationError;

export type ShippingLine = {
  __typename?: 'ShippingLine';
  customFields?: Maybe<Scalars['JSON']['output']>;
  discountedPrice: Scalars['Money']['output'];
  discountedPriceWithTax: Scalars['Money']['output'];
  discounts: Array<Discount>;
  id: Scalars['ID']['output'];
  price: Scalars['Money']['output'];
  priceWithTax: Scalars['Money']['output'];
  shippingMethod: ShippingMethod;
};

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  calculator: ConfigurableOperation;
  checker: ConfigurableOperation;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  fulfillmentHandlerCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  translations: Array<ShippingMethodTranslation>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ShippingMethodList = PaginatedList & {
  __typename?: 'ShippingMethodList';
  items: Array<ShippingMethod>;
  totalItems: Scalars['Int']['output'];
};

export type ShippingMethodQuote = {
  __typename?: 'ShippingMethodQuote';
  code: Scalars['String']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Money']['output'];
  priceWithTax: Scalars['Money']['output'];
};

export type ShippingMethodTranslation = {
  __typename?: 'ShippingMethodTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SinglePrice = {
  __typename?: 'SinglePrice';
  value: Scalars['Money']['output'];
};

export type SocialLink = Node & {
  __typename?: 'SocialLink';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  platform: SocialLinkType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum SocialLinkType {
  Discord = 'DISCORD',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Other = 'OTHER',
  Pinterest = 'PINTEREST',
  Tiktok = 'TIKTOK',
  Twitter = 'TWITTER',
  Website = 'WEBSITE',
  Youtube = 'YOUTUBE'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StatusType {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type StringCustomFieldConfig = CustomField & {
  __typename?: 'StringCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']['output']>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<StringFieldOption>>;
  pattern?: Maybe<Scalars['String']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type StringFieldOption = {
  __typename?: 'StringFieldOption';
  label?: Maybe<Array<LocalizedString>>;
  value: Scalars['String']['output'];
};

export type StringListOperators = {
  inList: Scalars['String']['input'];
};

export type StringOperators = {
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notEq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type StringStructFieldConfig = StructField & {
  __typename?: 'StringStructFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']['output']>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<StringFieldOption>>;
  pattern?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type StripeBalanceNotZeroError = ErrorResult & {
  __typename?: 'StripeBalanceNotZeroError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type StructCustomFieldConfig = CustomField & {
  __typename?: 'StructCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  fields: Array<StructFieldConfig>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type StructField = {
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  list?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type StructFieldConfig = BooleanStructFieldConfig | DateTimeStructFieldConfig | FloatStructFieldConfig | IntStructFieldConfig | StringStructFieldConfig | TextStructFieldConfig;

export type SubmitFeedbackInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  feedbackRating: Scalars['Int']['input'];
  headline: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type SubmitFeedbackResult = CustomerFeedback | FeedbackExistsError | OrderStateTransitionError;

export type SubmitProductReviewInput = {
  authorLocation?: InputMaybe<Scalars['String']['input']>;
  authorName: Scalars['String']['input'];
  body: Scalars['String']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  rating: Scalars['Float']['input'];
  summary: Scalars['String']['input'];
};

export type SubmitSearchLocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  radius: Scalars['Float']['input'];
};

export type SubmitUploadLocationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cityName: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  countryName: Scalars['String']['input'];
  districtName?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean']['output'];
};

export type SumSubResult = Node & {
  __typename?: 'SumSubResult';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reviewAnswer?: Maybe<Scalars['String']['output']>;
  reviewRejectType?: Maybe<Scalars['String']['output']>;
  reviewStatus: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SumSubToken = {
  __typename?: 'SumSubToken';
  applicantId: Scalars['ID']['output'];
  flowName: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type Surcharge = Node & {
  __typename?: 'Surcharge';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Money']['output'];
  priceWithTax: Scalars['Money']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  taxLines: Array<TaxLine>;
  taxRate: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Tag = Node & {
  __typename?: 'Tag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type TagList = PaginatedList & {
  __typename?: 'TagList';
  items: Array<Tag>;
  totalItems: Scalars['Int']['output'];
};

export type TaxCategory = Node & {
  __typename?: 'TaxCategory';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TaxLine = {
  __typename?: 'TaxLine';
  description: Scalars['String']['output'];
  taxRate: Scalars['Float']['output'];
};

export type TaxRate = Node & {
  __typename?: 'TaxRate';
  category: TaxCategory;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  customerGroup?: Maybe<CustomerGroup>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
  zone: Zone;
};

export type TaxRateList = PaginatedList & {
  __typename?: 'TaxRateList';
  items: Array<TaxRate>;
  totalItems: Scalars['Int']['output'];
};

export type TextCustomFieldConfig = CustomField & {
  __typename?: 'TextCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nullable?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type TextStructFieldConfig = StructField & {
  __typename?: 'TextStructFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  ui?: Maybe<Scalars['JSON']['output']>;
};

export type TopCollectionResult = {
  __typename?: 'TopCollectionResult';
  collection: Collection;
  score: Scalars['Int']['output'];
};

export type TransitionFulfillmentToStateResult = Fulfillment | FulfillmentStateTransitionError;

export type TransitionOrderToStateResult = Order | OrderStateTransitionError;

export type TwitterCard = {
  __typename?: 'TwitterCard';
  card?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UnreadMarker = {
  __typename?: 'UnreadMarker';
  entityId: Scalars['ID']['output'];
  entityName: Scalars['String']['output'];
};

export type UpdateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Scalars['JSON']['input']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  streetLine1?: InputMaybe<Scalars['String']['input']>;
  streetLine2?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAssetCustomFieldsInput = {
  uploadContext?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAssetInput = {
  customFields?: InputMaybe<UpdateAssetCustomFieldsInput>;
  focalPoint?: InputMaybe<CoordinateInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateBusinessInput = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerCustomFieldsInput = {
  allowNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerEmailAddressResult = IdentifierChangeTokenExpiredError | IdentifierChangeTokenInvalidError | NativeAuthStrategyError | Success;

export type UpdateCustomerInput = {
  customFields?: InputMaybe<UpdateCustomerCustomFieldsInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerPasswordResult = InvalidCredentialsError | NativeAuthStrategyError | PasswordValidationError | Success;

export type UpdateFeedbackInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  feedbackId: Scalars['ID']['input'];
  feedbackRating?: InputMaybe<Scalars['Int']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInquiryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectedPrice?: InputMaybe<Scalars['Float']['input']>;
  inquiryId: Scalars['ID']['input'];
  productName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOrderCustomFieldsInput = {
  afterImageComment?: InputMaybe<Array<Scalars['String']['input']>>;
  afterImageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  beforeImageComment?: InputMaybe<Array<Scalars['String']['input']>>;
  beforeImageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  durationId?: InputMaybe<Scalars['ID']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderInput = {
  customFields?: InputMaybe<UpdateOrderCustomFieldsInput>;
};

export type UpdateOrderItemsResult = InsufficientStockError | NegativeQuantityError | Order | OrderInterceptorError | OrderLimitError | OrderModificationError;

export type UpdateOwnerProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  delivery?: InputMaybe<ProductDeliveryOptions>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pricing: Array<UpdateProductPriceInput>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchasePrice?: InputMaybe<Scalars['Float']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  shippingFee?: InputMaybe<Scalars['Int']['input']>;
  subCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateProductPriceInput = {
  basePrice: Scalars['Int']['input'];
  prices?: InputMaybe<Scalars['JSON']['input']>;
  variantId: Scalars['ID']['input'];
};

export type UpdateProfileInput = {
  assets?: InputMaybe<Array<CreateAssetInput>>;
  business?: InputMaybe<UpdateBusinessInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  featuredAsset?: InputMaybe<CreateAssetInput>;
};

export type UpdateProfileResult = AssetsOutOfBoundsError | MimeTypeError | Profile;

export type UploadLocation = Node & {
  __typename?: 'UploadLocation';
  address?: Maybe<Scalars['String']['output']>;
  cityName: Scalars['String']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  countryName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  districtName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type User = Node & {
  __typename?: 'User';
  authenticationMethods: Array<AuthenticationMethod>;
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<UserCustomFields>;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime']['output'];
  verified: Scalars['Boolean']['output'];
};

export type UserCustomFields = {
  __typename?: 'UserCustomFields';
  appTutorialSeen?: Maybe<Scalars['Boolean']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  firstLogin?: Maybe<Scalars['DateTime']['output']>;
  identified?: Maybe<Scalars['Boolean']['output']>;
  interests?: Maybe<UserInterests>;
  notificationPreferences?: Maybe<Array<Scalars['String']['output']>>;
  paypalEmail?: Maybe<Scalars['String']['output']>;
  stripeAccount?: Maybe<Scalars['String']['output']>;
  subscriberHash?: Maybe<Scalars['String']['output']>;
  webTutorialSeen?: Maybe<Scalars['Boolean']['output']>;
};

export type UserDevice = Node & {
  __typename?: 'UserDevice';
  createdAt: Scalars['DateTime']['output'];
  deviceName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modelName?: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserDeviceFilterParameter = {
  _and?: InputMaybe<Array<UserDeviceFilterParameter>>;
  _or?: InputMaybe<Array<UserDeviceFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deviceName?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  modelName?: InputMaybe<StringOperators>;
  platform?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type UserDeviceList = PaginatedList & {
  __typename?: 'UserDeviceList';
  items: Array<UserDevice>;
  totalItems: Scalars['Int']['output'];
};

export type UserDeviceListOptions = {
  filter?: InputMaybe<UserDeviceFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<UserDeviceSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type UserDeviceSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelName?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum UserEntityRelation {
  NoRelation = 'NO_RELATION',
  UserHasRelation = 'USER_HAS_RELATION',
  UserIsAdmin = 'USER_IS_ADMIN',
  UserIsOwner = 'USER_IS_OWNER'
}

export type UserInterests = {
  __typename?: 'UserInterests';
  collections?: Maybe<Array<Collection>>;
  preference: Preference;
  user: User;
};

export type UserInterestsInput = {
  collectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  preferenceId: Scalars['ID']['input'];
};

export type ValidateIdentityResult = IsIdentified | OrderStateTransitionError;

export type VerificationTokenExpiredError = ErrorResult & {
  __typename?: 'VerificationTokenExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type VerificationTokenInvalidError = ErrorResult & {
  __typename?: 'VerificationTokenInvalidError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type VerifyCustomerAccountResult = CurrentUser | MissingPasswordError | NativeAuthStrategyError | PasswordAlreadySetError | PasswordValidationError | VerificationTokenExpiredError | VerificationTokenInvalidError;

export type Voucher = Node & {
  __typename?: 'Voucher';
  amount: Scalars['Int']['output'];
  claimed: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Int']['output'];
};

export type VoucherAlreadyClaimedError = ErrorResult & {
  __typename?: 'VoucherAlreadyClaimedError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type VoucherEmptyError = ErrorResult & {
  __typename?: 'VoucherEmptyError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type VoucherExpiredError = ErrorResult & {
  __typename?: 'VoucherExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type VoucherFilterParameter = {
  _and?: InputMaybe<Array<VoucherFilterParameter>>;
  _or?: InputMaybe<Array<VoucherFilterParameter>>;
  amount?: InputMaybe<NumberOperators>;
  claimed?: InputMaybe<BooleanOperators>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  expiresAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  value?: InputMaybe<NumberOperators>;
};

export type VoucherList = PaginatedList & {
  __typename?: 'VoucherList';
  items: Array<Voucher>;
  totalItems: Scalars['Int']['output'];
};

export type VoucherListOptions = {
  filter?: InputMaybe<VoucherFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<VoucherSortParameter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type VoucherSortParameter = {
  amount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type VoucherWrongOrderStateError = ErrorResult & {
  __typename?: 'VoucherWrongOrderStateError';
  errorCode: ErrorCode;
  message: Scalars['String']['output'];
};

export type Zone = Node & {
  __typename?: 'Zone';
  createdAt: Scalars['DateTime']['output'];
  customFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  members: Array<Region>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AcceptRequestResult": [
      "OrderStateTransitionError",
      "Request"
    ],
    "ActiveOrderResult": [
      "NoActiveOrderError",
      "Order"
    ],
    "AddLateImagesToOrderResult": [
      "ImagesCountError",
      "Order"
    ],
    "AddPaymentToOrderResult": [
      "IneligiblePaymentMethodError",
      "NoActiveOrderError",
      "Order",
      "OrderPaymentStateError",
      "OrderStateTransitionError",
      "PaymentDeclinedError",
      "PaymentFailedError"
    ],
    "ApplyCouponCodeResult": [
      "CouponCodeExpiredError",
      "CouponCodeInvalidError",
      "CouponCodeLimitError",
      "Order"
    ],
    "ApplyVoucherResult": [
      "OrderAlreadyHasVoucherError",
      "Voucher",
      "VoucherAlreadyClaimedError",
      "VoucherEmptyError",
      "VoucherExpiredError",
      "VoucherWrongOrderStateError"
    ],
    "AuthenticationResult": [
      "CurrentUser",
      "InvalidCredentialsError",
      "NotVerifiedError"
    ],
    "BlockProductResult": [
      "DateOutOfBoundsError",
      "Success"
    ],
    "BlockUserResult": [
      "ActiveOrderError",
      "Success"
    ],
    "CancelOrderResult": [
      "CancelActiveOrderError",
      "EmptyOrderLineSelectionError",
      "MultipleOrderError",
      "Order",
      "OrderStateTransitionError",
      "QuantityTooGreatError"
    ],
    "ClaimVoucherResult": [
      "Voucher",
      "VoucherAlreadyClaimedError"
    ],
    "CommunityInviteResult": [
      "CommunityInvitation",
      "CommunityInvitationExistsError"
    ],
    "CommunityJoinResult": [
      "AlreadyCommunityMemberError",
      "Community",
      "JoinRequirementError",
      "NoCommunityAccessError"
    ],
    "CreateAssetResult": [
      "Asset",
      "MimeTypeError"
    ],
    "CreateRequestResult": [
      "DateOutOfBoundsError",
      "ProductBlockedOrBookedError",
      "Request",
      "RequestCollisionError"
    ],
    "CustomField": [
      "BooleanCustomFieldConfig",
      "DateTimeCustomFieldConfig",
      "FloatCustomFieldConfig",
      "IntCustomFieldConfig",
      "LocaleStringCustomFieldConfig",
      "LocaleTextCustomFieldConfig",
      "RelationCustomFieldConfig",
      "StringCustomFieldConfig",
      "StructCustomFieldConfig",
      "TextCustomFieldConfig"
    ],
    "CustomFieldConfig": [
      "BooleanCustomFieldConfig",
      "DateTimeCustomFieldConfig",
      "FloatCustomFieldConfig",
      "IntCustomFieldConfig",
      "LocaleStringCustomFieldConfig",
      "LocaleTextCustomFieldConfig",
      "RelationCustomFieldConfig",
      "StringCustomFieldConfig",
      "StructCustomFieldConfig",
      "TextCustomFieldConfig"
    ],
    "DeleteProductResult": [
      "ActiveOrderError",
      "DeletionResponse"
    ],
    "DeleteUploadLocationResult": [
      "DefaultLocationDeleteError",
      "DeletionResponse"
    ],
    "ErrorResult": [
      "ActiveOrderError",
      "AlreadyCommunityMemberError",
      "AlreadyLoggedInError",
      "AlreadyRefundedError",
      "AssetsOutOfBoundsError",
      "CancelActiveOrderError",
      "CommunityInvitationExistsError",
      "CouponCodeExpiredError",
      "CouponCodeInvalidError",
      "CouponCodeLimitError",
      "DateOutOfBoundsError",
      "DefaultLocationDeleteError",
      "EmailAddressConflictError",
      "EmptyOrderLineSelectionError",
      "FeedbackExistsError",
      "FulfillmentStateTransitionError",
      "GuestCheckoutError",
      "IdentifierChangeTokenExpiredError",
      "IdentifierChangeTokenInvalidError",
      "ImagesCountError",
      "IneligiblePaymentMethodError",
      "IneligibleShippingMethodError",
      "InsufficientStockError",
      "InvalidCredentialsError",
      "JoinRequirementError",
      "MimeTypeError",
      "MissingInputError",
      "MissingPasswordError",
      "MultipleOrderError",
      "NativeAuthStrategyError",
      "NegativeQuantityError",
      "NoActiveOrderError",
      "NoCommunityAccessError",
      "NotVerifiedError",
      "NothingToRefundError",
      "OrderAlreadyHasVoucherError",
      "OrderInterceptorError",
      "OrderLimitError",
      "OrderModificationError",
      "OrderPaymentStateError",
      "OrderStateTransitionError",
      "PasswordAlreadySetError",
      "PasswordResetTokenExpiredError",
      "PasswordResetTokenInvalidError",
      "PasswordValidationError",
      "PaymentDeclinedError",
      "PaymentFailedError",
      "PaymentOrderMismatchError",
      "PaymentStateTransitionError",
      "ProductBlockedOrBookedError",
      "QuantityTooGreatError",
      "RefundOrderStateError",
      "RefundStateTransitionError",
      "RequestCollisionError",
      "StripeBalanceNotZeroError",
      "VerificationTokenExpiredError",
      "VerificationTokenInvalidError",
      "VoucherAlreadyClaimedError",
      "VoucherEmptyError",
      "VoucherExpiredError",
      "VoucherWrongOrderStateError"
    ],
    "ExternalIndexResponse": [
      "CommunityResponse",
      "ProducttemplateResponse"
    ],
    "ExternalIndexResult": [
      "CommunityResult",
      "ProducttemplateResult"
    ],
    "GetPaymentClientSecretResult": [
      "ClientSecret",
      "IneligiblePaymentMethodError"
    ],
    "InquiryResult": [
      "DateOutOfBoundsError",
      "Inquiry"
    ],
    "NativeAuthenticationResult": [
      "CurrentUser",
      "InvalidCredentialsError",
      "NativeAuthStrategyError",
      "NotVerifiedError"
    ],
    "Node": [
      "Address",
      "Asset",
      "AuthenticationMethod",
      "BusinessProfile",
      "Channel",
      "Collection",
      "Community",
      "CommunityInvitation",
      "CommunityLocation",
      "CommunityMember",
      "Conversation",
      "Country",
      "Customer",
      "CustomerFeedback",
      "CustomerGroup",
      "Facet",
      "FacetValue",
      "Favorite",
      "Fulfillment",
      "HistoryEntry",
      "Inquiry",
      "Invoice",
      "MigratedUser",
      "Order",
      "OrderLine",
      "Payment",
      "PaymentMethod",
      "Payout",
      "Preference",
      "Product",
      "ProductClaimPeriod",
      "ProductOption",
      "ProductOptionGroup",
      "ProductReview",
      "ProductTemplate",
      "ProductTemplateFaq",
      "ProductTemplateSpecs",
      "ProductTemplateTranslation",
      "ProductVariant",
      "ProductVariantPriceTiers",
      "Profile",
      "ProfileStatistics",
      "Promotion",
      "Province",
      "Refund",
      "ReleaseNote",
      "Request",
      "Role",
      "SEO",
      "SearchLocation",
      "Seller",
      "ShippingMethod",
      "SocialLink",
      "SumSubResult",
      "Surcharge",
      "Tag",
      "TaxCategory",
      "TaxRate",
      "UploadLocation",
      "User",
      "UserDevice",
      "Voucher",
      "Zone"
    ],
    "OrderDeliveredResult": [
      "ImagesCountError",
      "Order",
      "OrderStateTransitionError"
    ],
    "PaginatedList": [
      "AssetList",
      "CollectionList",
      "CommunityInvitationList",
      "CommunityList",
      "CommunityMemberList",
      "ConversationList",
      "CountryList",
      "CustomerFeedbackList",
      "CustomerList",
      "FacetList",
      "FacetValueList",
      "HistoryEntryList",
      "InquiryList",
      "InvoiceList",
      "OrderList",
      "PreferenceList",
      "ProductClaimPeriodList",
      "ProductList",
      "ProductReviewList",
      "ProductTemplateList",
      "ProductVariantList",
      "PromotionList",
      "ProvinceList",
      "ReleaseNoteList",
      "RequestList",
      "RoleList",
      "ShippingMethodList",
      "TagList",
      "TaxRateList",
      "UserDeviceList",
      "VoucherList"
    ],
    "RefreshCustomerVerificationResult": [
      "NativeAuthStrategyError",
      "Success"
    ],
    "RefundOrderResult": [
      "AlreadyRefundedError",
      "MultipleOrderError",
      "NothingToRefundError",
      "OrderStateTransitionError",
      "PaymentOrderMismatchError",
      "QuantityTooGreatError",
      "Refund",
      "RefundOrderStateError",
      "RefundStateTransitionError"
    ],
    "Region": [
      "Country",
      "Province"
    ],
    "RegisterCustomerAccountResult": [
      "MissingPasswordError",
      "NativeAuthStrategyError",
      "PasswordValidationError",
      "Success"
    ],
    "RemoveOrderItemsResult": [
      "Order",
      "OrderInterceptorError",
      "OrderModificationError"
    ],
    "RequestPasswordResetResult": [
      "NativeAuthStrategyError",
      "Success"
    ],
    "RequestUpdateCustomerEmailAddressResult": [
      "EmailAddressConflictError",
      "InvalidCredentialsError",
      "NativeAuthStrategyError",
      "Success"
    ],
    "ResetPasswordResult": [
      "CurrentUser",
      "NativeAuthStrategyError",
      "NotVerifiedError",
      "PasswordResetTokenExpiredError",
      "PasswordResetTokenInvalidError",
      "PasswordValidationError"
    ],
    "SearchResultPrice": [
      "PriceRange",
      "SinglePrice"
    ],
    "SetCustomerForOrderResult": [
      "AlreadyLoggedInError",
      "EmailAddressConflictError",
      "GuestCheckoutError",
      "NoActiveOrderError",
      "Order"
    ],
    "SetOrderShippingMethodResult": [
      "IneligibleShippingMethodError",
      "NoActiveOrderError",
      "Order",
      "OrderModificationError"
    ],
    "StructField": [
      "BooleanStructFieldConfig",
      "DateTimeStructFieldConfig",
      "FloatStructFieldConfig",
      "IntStructFieldConfig",
      "StringStructFieldConfig",
      "TextStructFieldConfig"
    ],
    "StructFieldConfig": [
      "BooleanStructFieldConfig",
      "DateTimeStructFieldConfig",
      "FloatStructFieldConfig",
      "IntStructFieldConfig",
      "StringStructFieldConfig",
      "TextStructFieldConfig"
    ],
    "SubmitFeedbackResult": [
      "CustomerFeedback",
      "FeedbackExistsError",
      "OrderStateTransitionError"
    ],
    "TransitionFulfillmentToStateResult": [
      "Fulfillment",
      "FulfillmentStateTransitionError"
    ],
    "TransitionOrderToStateResult": [
      "Order",
      "OrderStateTransitionError"
    ],
    "UpdateCustomerEmailAddressResult": [
      "IdentifierChangeTokenExpiredError",
      "IdentifierChangeTokenInvalidError",
      "NativeAuthStrategyError",
      "Success"
    ],
    "UpdateCustomerPasswordResult": [
      "InvalidCredentialsError",
      "NativeAuthStrategyError",
      "PasswordValidationError",
      "Success"
    ],
    "UpdateOrderItemsResult": [
      "InsufficientStockError",
      "NegativeQuantityError",
      "Order",
      "OrderInterceptorError",
      "OrderLimitError",
      "OrderModificationError"
    ],
    "UpdateProfileResult": [
      "AssetsOutOfBoundsError",
      "MimeTypeError",
      "Profile"
    ],
    "ValidateIdentityResult": [
      "IsIdentified",
      "OrderStateTransitionError"
    ],
    "VerifyCustomerAccountResult": [
      "CurrentUser",
      "MissingPasswordError",
      "NativeAuthStrategyError",
      "PasswordAlreadySetError",
      "PasswordValidationError",
      "VerificationTokenExpiredError",
      "VerificationTokenInvalidError"
    ]
  }
};
      export default result;
    